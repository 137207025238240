<template>
  <div v-show="show" class="container-form-model-custom">
    <div
      class="image-cropper basic-container max-w-[600px] rounded-lg !bg-white w-full h-fit flex flex-col justify-center items-center"
    >
      <!-- Cropbox header -->
      <header class="form-header w-full">
        <p class="text-[#101828] text-[20px] noto700">プロフィール写真編集</p>
        <button @click="onClose" class="notosans500 text-[#667085]">
          閉じる
        </button>
      </header>
      <!-- cropbox body -->
      <div class="w-full flex-1">
        <VuePictureCropper
          :key="cropperKey"
          :boxStyle="{
            width: '100%',
            height: '400px',
            backgroundColor: '#f8f8f8',
            margin: 'auto'
          }"
          :img="imageSrc"
          :options="{
            fillColor: 'white',
            dragMode: 'move', // use this for dragging image
            // dragMode: 'drag', use this for dragging cropbox not image

            cropBoxMovable: true, //disable cropbox movable
            cropBoxResizable: true, //disable cropbox resizeable
            guides: false, //hide guide line of cropbox
            zoomOnWheel: true //disable scroll on mouse wheel
          }"
          :presetMode="{
            //custom cropbox
            // mode: 'round',
          }"
          @ready="ready"
        />
      </div>
      <div class="max-w-[300px] w-full flex items-center py-3 gap-4">
        <span
          class="cursor-pointer"
          @click="handleZoomOut"
          v-html="zoomOutIcon"
        ></span>
        <a-slider
          class="w-full custom-slider"
          id="cropper-slider-zoom-img"
          v-model:value="sliderValue"
          :min="0"
          :max="100"
          :step="1"
          :trackStyle="{ backgroundColor: '#EF6820', height: '6px' }"
          :handleStyle="{
            backgroundColor: '#EF6820'
          }"
        />
        <span
          class="cursor-pointer"
          @click="handleZoomIn"
          v-html="zoomInIcon"
        ></span>
      </div>
      <!-- cropbox footer -->
      <div
        class="cropbox-footer flex w-full gap-2 px-5 py-3 border-t border-[#D0D5DD]"
      >
        <button
          class="done-btn flex-1 border py-3 border-[#EF6820] flex justify-center items-center rounded-lg bg-white text-[#EF6820] inter-400"
          @click="reset"
        >
          キャンセル
        </button>
        <button
          class="done-btn flex-1 flex py-3 justify-center items-center rounded-lg bg-[#EF6820] text-white gap-2 inter-400"
          @click="done"
        >
          <span v-html="saveOutlinedIcon"></span>
          保存
        </button>
      </div>
    </div>
  </div>
</template>
  
  <script setup>
import { onMounted, onUnmounted, ref, watch } from 'vue';
import VuePictureCropper, { cropper } from 'vue-picture-cropper';
import {
  saveOutlinedIcon,
  zoomInIcon,
  zoomOutIcon
} from '../../assets/constant/svg';

const props = defineProps({
  // show-hide modal
  show: {
    type: Boolean,
    required: false
  },
  // hide-modal function
  onClose: {
    type: Function,
    required: true
  },
  // image source cropper
  imageSrc: {
    type: String,
    required: true
  },
  ratioCropper: {
    type: Number,
    default: null
  },
  // callback function after done cropping
  callBack: {
    type: Function,
    required: true
  }
});

const centerImage = () => {
  if (cropper) {
    const containerData = cropper.getContainerData();
    const imageData = cropper.getImageData();

    const left = (containerData.width - imageData.width) / 2;
    const top = (containerData.height - imageData.height) / 2;

    cropper.moveTo(left, top);
  }
};

const aspectRatio = ref(1);

const cropperKey = ref(0);

const sliderValue = ref(0);

const ready = () => {
  if (cropper) {
    // get width and height of img
    const imgData = cropper.getImageData();
    const imgWidth = imgData.naturalWidth;
    const imgHeight = imgData.naturalHeight;
    // size of cropbox 350x350
    const cropBoxSize = 350;
    // center cropbox when open modal
    const containerData = cropper.getContainerData();
    cropper.setCropBoxData({
      left: (containerData.width - cropBoxSize) / 2,
      top: (containerData.height - cropBoxSize) / 2,
      width: cropBoxSize,
      height: cropBoxSize
    });
    // width > height of image as props
    if (imgWidth > imgHeight) {
      const zoomRatio = cropBoxSize / imgHeight;
      cropper.zoomTo(zoomRatio);
      // width < height of image as props
    } else if (imgWidth < imgHeight) {
      const zoomRatio = cropBoxSize / imgHeight;
      cropper.zoomTo(zoomRatio);
      // width = height of image as props
    } else {
      const zoomRatio = cropBoxSize / imgHeight;
      cropper.zoomTo(zoomRatio);
    }
  }
};

const createFileName = (blob) => {
  const mimeToExtension = {
    'image/jpeg': '.jpg',
    'image/png': '.png',
    'image/gif': '.gif'
  };

  const extension = mimeToExtension[blob.type] || '.jpg';
  return `image_${Date.now()}${extension}`;
};

const done = async () => {
  if (!cropper) return;

  // Lấy kích thước ảnh được cắt
  const croppedCanvas = cropper.getCroppedCanvas({
    imageSmoothingEnabled: true,
    imageSmoothingQuality: 'high'
  });

  if (!croppedCanvas) return;

  // Tạo canvas mới có cùng kích thước với cropper
  const canvas = document.createElement('canvas');
  canvas.width = croppedCanvas.width;
  canvas.height = croppedCanvas.height;

  const ctx = canvas.getContext('2d');
  if (!ctx) return;

  // Đặt nền trong suốt
  ctx.clearRect(0, 0, canvas.width, canvas.height);
  ctx.drawImage(croppedCanvas, 0, 0);

  canvas.toBlob((blob) => {
    if (!blob) return;

    const file = new File([blob], createFileName(blob), { type: blob.type });
    props.callBack(file, URL.createObjectURL(blob));
    props.onClose();
  }, 'image/png'); // Giữ PNG để nền trong suốt
};

const put = () => {
  const img = new Image();
  img.src = props.imageSrc;
  img.onload = () => {
    if (!props.ratioCropper) {
      aspectRatio.value = img.naturalWidth / img.naturalHeight;
    } else {
      aspectRatio.value = props.ratioCropper;
    }
  };
};
const resetRatio = () => {
  cropper?.setAspectRatio(aspectRatio.value);
};

const reset = () => {
  put();
  cropper?.reset();
  cropper?.setAspectRatio(aspectRatio.value);
  props.onClose();
};

const handleZoomOut = () => {
  if (cropper && sliderValue.value > 0) {
    sliderValue.value -= 10;
  }
};

const handleZoomIn = () => {
  if (cropper && sliderValue.value < 100) {
    sliderValue.value += 10;
  }
};

watch(sliderValue, (newValue) => {
  if (cropper) {
    const zoomRatio = newValue / 50 + 0.2;
    cropper.zoomTo(zoomRatio);
  }
});

// update key for new image
watch(
  () => props.show,
  (newValue) => {
    if (newValue) {
      cropperKey.value += 1;
    }
  }
);

watch(aspectRatio, () => {
  resetRatio();
});

watch(props, () => {
  put();
});
</script>
  <style lang="scss">
.custom-slider .ant-slider-handle {
  width: 20px !important;
  height: 20px !important;
  border-radius: 50% !important;
  transform: translate(-50%, -25%) !important;
}
.custom-slider .ant-slider-handle::after {
  background-color: transparent !important;
  border-radius: 50% !important;
  box-shadow: none !important;
}
.custom-slider .ant-slider-handle:hover::after {
  box-shadow: none !important;
}
.cropper-bg {
  background-image: none !important;
}
.cropper-modal {
  background-color: white !important;
}
.cropbox-footer {
  @media (min-width: 440px) {
    flex-direction: row;
  }
  flex-direction: column;
}
</style>