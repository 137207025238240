<template>
  <div
    v-if="!layoutMobile"
    class="user-detail-active py-[21px] bg-[#f9fafb] w-full"
  >
    <div
      class="container mx-auto flex flex-col justify-start items-start gap-[20px]"
    >
      <div class="title flex justify-start items-center gap-[8px]">
        <button @click="goBackRouter" v-html="ArrowLeftNotLineHos"></button>
        <div
          class="btn-back flex justify-center items-center min-w-[88px] px-[10px] h-[36px] bg-[#FDEAD7] rounded-[6px]"
        >
          <span class="text-[#F38744] text-[16px] noto500 leading-[20px]">{{
            $t('common.personal_info')
          }}</span>
        </div>
      </div>
      <div
        class="content bg-white flex flex-col justify-start items-center gap-[27px] w-full min-h-[348px] px-[24px] py-[20px] border-solid border-[#D0D5DD] border-[1px] rounded-[12px]"
      >
        <div class="header w-full flex justify-between items-center">
          <h3 class="title noto700 text-[20px] leading-[28px] text-[#475467]">
            {{ $t('common.user_information') }}
          </h3>
          <div class="btns flex justify-center items-center gap-[8px]">
            <button
              @click="updateEnableLive"
              v-if="userDetails.enableLive === true"
              class="w-[60px] h-[42px] flex justify-center items-center rounded-[8px] bg-[#F04438] text-white noto500 text-[14px] leading-[18px]"
            >
              STOP
            </button>
            <button
              v-if="userDetails.enableLive === false"
              :disabled="loadCallApi"
              @click="handleUnBlockUserLive"
              class="flex justify-center items-center bg-[#12B76A] min-w-[130px] h-[42px] rounded-[8px]"
            >
              <span class="text-white text-[14px] noto500 leading-[18px]">{{
                $t('common.unblock_live')
              }}</span>
            </button>
            <button
              :disabled="loadCallApi"
              @click="handleDeletePermanentlyAccount"
              class="flex justify-center items-center bg-white min-w-[130px] h-[42px] rounded-[8px] border-solid border-[1px] border-[#F04438]"
            >
              <span class="text-[#F04438] text-[14px] noto500 leading-[18px]">{{
                $t('common.account_deletion')
              }}</span>
            </button>
            <button
              :disabled="loadCallApi"
              @click="handleDisableAccount"
              class="flex justify-center items-center min-w-[144px] h-[42px] bg-[#F38744] rounded-[8px]"
            >
              <span class="text-white text-[14px] noto500 leading-[18px]">{{
                $t('common.account_hidden')
              }}</span>
            </button>
            <button
              class="change-pwd h-[42px] fill-primary-text-white"
              @click="handleTurnOnPasswordForm"
            >
              <span v-html="LockIcon"></span>
              {{ $t('common.change_pwd') }}
            </button>
          </div>
        </div>
        <div
          class="information-user w-full flex flex-col justify-start items-start gap-[20px]"
        >
          <div class="avatar w-full">
            <div class="flex justify-start items-center gap-[8px]">
              <span
                v-on:click="handleUploadImage"
                class="text-[16px] min-w-[200px] noto500 leading-[20px] text-[#667085] text-left cursor-pointer"
              >
                {{ $t('common.profile_picture') }}
              </span>
              <div
                class="box-avatar flex justify-start items-center gap[8px]"
                @click="handleUploadImage"
                :style="{
                  pointerEvents: loadingUploadImage ? 'none' : 'auto',
                  userSelect: loadingUploadImage ? 'none' : 'auto',
                  opacity: loadingUploadImage ? '.5' : '1'
                }"
              >
                <div class="upload-avatar relative w-[80px] h-[80px] mr-[16px]">
                  <input
                    id="user_detail_avatar_upload"
                    type="file"
                    accept="image/*"
                    class="hidden"
                    v-on:change="handleFileInputChange"
                  />
                  <img
                    class="w-[80px] h-[80px] object-cover rounded-lg cursor-pointer"
                    style="box-shadow: rgba(99, 99, 99, 0.2) 0px 2px 8px 0px"
                    :src="avatar.photoImage"
                    @error="handleImageError"
                    alt=""
                    id="img-avatar"
                  />
                  <button
                    :class="{
                      'flex justify-center items-center absolute bottom-1 right-1 w-[32px] h-[32px] rounded-full bg-[#F38744]': true,
                      '!bg-white': loadingUploadImage
                    }"
                  >
                    <a-spin
                      v-if="loadingUploadImage"
                      class="flex justify-center items-center w-[20px] h-[20px]"
                    />
                    <span v-else class="icon-camera" v-html="IconCamera"></span>
                  </button>
                </div>
                <button
                  @click="(event) => handleDeleteAvatar(event)"
                  class="delete-avatar bg-white min-w-[110px] px-[10px] h-[42px] flex justify-center items-center gap-[6px] rounded-[8px] border-[1px] border-solid border-[#F04438]"
                >
                  <span class="icon-trash" v-html="TrashIcon"></span>
                  <span
                    class="text-[#F04438] text-[14px] noto500 leading-[18px]"
                    >{{ $t('common.delete_image') }}</span
                  >
                </button>
              </div>
            </div>
          </div>
          <span class="w-full h-[1px] bg-[#EAECF0]"> </span>

          <div class="user-name w-full">
            <label class="flex justify-start items-center gap-[8px]">
              <span
                class="min-w-[200px] text-[16px] noto500 leading-[20px] text-[#667085] text-left cursor-pointer"
              >
                {{ $t('common.username') }}
              </span>
              <input
                v-model="userDetails.nickName"
                class="h-[42px] flex-1 bg-white rounded-[8px] border-solid border-[1px] border-[#d0d5dd] outline-none px-[16px] text-[14px] noto400 leading-[18px]"
              />
            </label>
          </div>
          <span class="w-full h-[1px] bg-[#EAECF0]"></span>
          <div class="user-id w-full">
            <label class="flex justify-start items-center gap-[8px]">
              <span
                class="min-w-[200px] text-[16px] noto500 leading-[20px] text-[#667085] text-left cursor-pointer"
              >
                {{ $t('common.user_id_v2') }}
              </span>
              <input
                v-model="userDetails.userName"
                class="h-[42px] flex-1 bg-white rounded-[8px] border-solid border-[1px] border-[#d0d5dd] outline-none px-[16px] text-[14px] noto400 leading-[18px]"
              />
            </label>
          </div>
          <span class="w-full h-[1px] bg-[#EAECF0]"></span>
          <div class="user-id w-full">
            <label class="flex justify-start items-center gap-[8px] relative">
              <span
                class="min-w-[200px] text-[16px] noto500 leading-[20px] text-[#667085] text-left cursor-pointer"
              >
                {{ $t('common.password') }}
              </span>
              <input
                v-model="userDetails.passwordString"
                :type="isShowPasswordInputField ? 'text' : 'password'"
                disabled
                class="h-[42px] flex-1 bg-slate-100 rounded-[8px] border-solid border-[1px] border-[#d0d5dd] outline-none px-[16px] text-[14px] noto400 leading-[18px]"
              />
              <button
                class="absolute right-[14px] top-3"
                @click="handleShowHidePasswordField"
              >
                <span
                  v-html="
                    !isShowPasswordInputField
                      ? PasswordHideIconSmall
                      : PasswordShowIconSmall
                  "
                ></span>
              </button>
            </label>
          </div>
          <span class="w-full h-[1px] bg-[#EAECF0]"></span>
          <div class="w-full flex justify-end">
            <button
              class="button-submit bg-[#F38744] flex items-center py-3 px-4 rounded-lg text-white"
              type="submit"
              @click="handleUpdateUserInfo"
            >
              <label
                v-html="SaveSettingFilled"
                class="mr-[6px] cursor-pointer"
              ></label>
              {{ $t('common.keep') }}
            </button>
          </div>
        </div>
      </div>
    </div>
  </div>
  <div v-else class="user-detail-active bg-[#f9fafb] w-full">
    <div class="w-full flex flex-col justify-start items-start gap-[20px]">
      <div
        class="content bg-white flex flex-col justify-start items-center gap-[20px] w-full min-h-[348px] px-[24px] py-[20px] border-solid border-[#D0D5DD] border-[1px] rounded-[12px]"
      >
        <div class="flex items-center justify-between w-full">
          <div class="header flex justify-center items-center">
            <h3 class="title noto700 text-[20px] leading-[28px] text-[#475467]">
              {{ $t('common.user_information') }}
            </h3>
          </div>
          <button
            v-if="userDetails.enableLive === false"
            :disabled="loadCallApi"
            @click="handleUnBlockUserLive"
            class="flex justify-center items-center bg-[#12B76A] px-2 h-[40px] rounded-[8px] whitespace-nowrap"
          >
            <span class="text-white text-[14px] noto500 leading-[18px]">{{
              $t('common.unblock_live')
            }}</span>
          </button>
          <button
            @click="updateEnableLive"
            v-if="userDetails.enableLive === true"
            class="w-[60px] h-[40px] flex justify-center items-center rounded-[8px] bg-[#F04438] text-white noto500 text-[14px] leading-[18px]"
          >
            STOP
          </button>
        </div>
        <span class="w-full h-[1px] bg-[#EAECF0]"></span>
        <div
          class="information-user w-full flex flex-col justify-start items-start gap-[20px]"
        >
          <div class="avatar w-full">
            <div class="flex flex-col justify-start items-start gap-[12px]">
              <span
                v-on:click="handleUploadImage"
                class="text-[16px] min-w-[200px] noto500 leading-[20px] text-[#667085] text-left cursor-pointer"
              >
                {{ $t('common.profile_picture') }}
              </span>
              <div
                class="box-avatar flex justify-start items-center gap[8px]"
                @click="handleUploadImage"
                :style="{
                  pointerEvents: loadingUploadImage ? 'none' : 'auto',
                  userSelect: loadingUploadImage ? 'none' : 'auto',
                  opacity: loadingUploadImage ? '.5' : '1'
                }"
              >
                <div class="upload-avatar relative w-[80px] h-[80px] mr-[16px]">
                  <input
                    id="user_detail_avatar_upload"
                    type="file"
                    accept="image/*"
                    class="hidden"
                    v-on:change="handleFileInputChange"
                  />
                  <img
                    class="w-[80px] h-[80px] object-cover rounded-lg cursor-pointer"
                    style="box-shadow: rgba(99, 99, 99, 0.2) 0px 2px 8px 0px"
                    :src="avatar.photoImage"
                    @error="handleImageError"
                    alt=""
                    id="img-avatar"
                  />
                  <button
                    :class="{
                      'flex justify-center items-center absolute bottom-[2px] right-[2px] w-[32px] h-[32px] rounded-lg bg-[#F38744]': true,
                      '!bg-white': loadingUploadImage
                    }"
                  >
                    <a-spin
                      v-if="loadingUploadImage"
                      class="flex justify-center items-center w-[20px] h-[20px]"
                    />
                    <span v-else class="icon-camera" v-html="IconCamera"></span>
                  </button>
                </div>
                <button
                  @click="(event) => handleDeleteAvatar(event)"
                  class="delete-avatar bg-white min-w-[32px] px-[10px] h-[32px] flex justify-center items-center gap-[6px] rounded-[8px] border-[1px] border-solid border-[#F04438]"
                >
                  <span class="icon-trash" v-html="TrashIcon"></span>
                </button>
              </div>
            </div>
          </div>
          <span class="w-full h-[1px] bg-[#EAECF0]"></span>
          <div class="user-name w-full">
            <label class="flex flex-col justify-start items-start gap-[12px]">
              <span
                class="min-w-[200px] text-[16px] noto500 leading-[20px] text-[#667085] text-left cursor-pointer"
              >
                {{ $t('common.username') }}
              </span>
              <input
                v-model="userDetails.nickName"
                class="min-h-[42px] h-[42px] w-full flex-1 bg-white rounded-[8px] border-solid border-[1px] border-[#d0d5dd] outline-none px-[16px] text-[14px] noto400 leading-[18px]"
              />
            </label>
          </div>
          <span class="w-full h-[1px] bg-[#EAECF0]"> </span>
          <div class="user-id w-full">
            <label class="flex flex-col justify-start items-start gap-[12px]">
              <span
                class="min-w-[200px] text-[16px] noto500 leading-[20px] text-[#667085] text-left cursor-pointer"
              >
                {{ $t('common.user_id_v2') }}
              </span>
              <input
                v-model="userDetails.userName"
                class="min-h-[42px] h-[42px] w-full flex-1 bg-white rounded-[8px] border-solid border-[1px] border-[#d0d5dd] outline-none px-[16px] text-[14px] noto400 leading-[18px]"
              />
            </label>
          </div>
          <span class="w-full h-[1px] bg-[#EAECF0]"></span>
          <div class="user-id w-full">
            <label
              class="flex flex-col justify-start items-start gap-[12px] relative"
            >
              <span
                class="min-w-[200px] text-[16px] noto500 leading-[20px] text-[#667085] text-left cursor-pointer"
              >
                {{ $t('common.password') }}
              </span>
              <input
                v-model="userDetails.passwordString"
                :type="isShowPasswordInputField ? 'text' : 'password'"
                disabled
                class="h-[42px] flex-1 bg-slate-100 rounded-[8px] border-solid border-[1px] border-[#d0d5dd] outline-none px-[16px] text-[14px] noto400 leading-[18px]"
              />
              <button
                class="absolute right-[14px] top-11"
                @click="handleShowHidePasswordField"
              >
                <span
                  v-html="
                    !isShowPasswordInputField
                      ? PasswordHideIconSmall
                      : PasswordShowIconSmall
                  "
                ></span>
              </button>
            </label>
          </div>
        </div>
        <span class="w-full h-[1px] bg-[#EAECF0]"></span>
        <div
          class="btns flex flex-wrap justify-center items-center gap-[8px] w-full"
        >
          <button
            :disabled="loadCallApi"
            @click="handleDeletePermanentlyAccount"
            class="flex-1 flex justify-center items-center bg-white min-w-[130px] h-[40px] rounded-[8px] border-solid border-[1px] border-[#F04438]"
          >
            <span class="text-[#F04438] text-[14px] noto500 leading-[18px]">{{
              $t('common.account_deletion')
            }}</span>
          </button>
          <button
            :disabled="loadCallApi"
            @click="handleDisableAccount"
            class="flex-1 flex justify-center items-center min-w-[144px] h-[40px] bg-[#F38744] rounded-[8px]"
          >
            <span class="text-white text-[14px] noto500 leading-[18px]">{{
              $t('common.account_hidden')
            }}</span>
          </button>
        </div>
        <button
          class="button-submit bg-[#F38744] w-full flex items-center justify-center h-[40px] px-4 rounded-lg text-white"
          type="submit"
          @click="handleUpdateUserInfo"
        >
          <label
            v-html="SaveSettingFilled"
            class="mr-[6px] cursor-pointer"
          ></label>
          {{ $t('common.keep') }}
        </button>
      </div>
    </div>
  </div>
  <ImageCropper
    :show="showImageCropper"
    :imageSrc="imageSrcCropper"
    :ratioCropper="ratioCropper"
    :onClose="hideImageCropper"
    :callBack="callBackImageCropper"
  />
</template>
<script setup>
import { computed, isRef, onMounted, ref, watch, reactive } from 'vue';
import { useI18n } from 'vue-i18n';
import { useRoute, useRouter } from 'vue-router';
import { useStore } from 'vuex';
import ImageCropper from '../../master/ImageCropper.vue';
import {
  ArrowLeftNotLineHos,
  IconCamera,
  PasswordHideIconSmall,
  PasswordShowIcon,
  PasswordShowIconSmall,
  TrashIcon
} from '../../../assets/constant/svg';
import {
  addModal,
  getAvatarUserByName,
  getStatusUser,
  handleImageError,
  showAlert
} from '../../../helpers';
import Breadcrumd from '../../master/Breadcrumd.vue';
import ConfirmModal from './components/ConfirmModal.vue';
import { getToken } from '../../../helpers/token';
import ConfirmEnableLive from '../StoreManagermentNew/components/ConfirmEnableLive.vue';
import { LockIcon, SaveSettingFilled } from '../../../assets/constant/constant';
import FormUpdatePassword from './components/FormUpdatePassword.vue';

const store = useStore();
const route = useRoute();
const router = useRouter();
const layoutMobile = computed(() => store.state.layoutMobile);
const { t } = useI18n();
const loading = ref(true);
const loadCallApi = ref(false);
const loadingUploadImage = ref(false);
// const avatar = ref(null);
const avatar = reactive({
  photoFile: null,
  photoImage: ''
});

const isShowPasswordInputField = ref(false);
const userId = ref(null);

const avatarSrc = ref('');
const avatarFile = ref(null);

const showImageCropper = ref(false);
const imageSrcCropper = ref('');
const ratioCropper = ref(1);
const callBackImageCropper = ref(null);

const userDetails = computed(() => {
  return store.state.userDetails;
});

const hideImageCropper = () => {
  showImageCropper.value = false;
};

const defaultShopInfo = {
  name: '',
  phone: '',
  email: '',
  address: '',
  location: ''
};

const handleCropper = ({ imageState, imageSrc, ratio, index }) => {
  imageSrcCropper.value = imageSrc;
  ratioCropper.value = ratio;
  callBackImageCropper.value = getImageCropper({ imageState, index });
  showImageCropper.value = true;
};

const shopInfo = computed(() => {
  return { ...defaultShopInfo, ...store.state.shopDetails };
});

const handleTurnOnPasswordForm = () => {
  addModal(FormUpdatePassword, {
    userId: userId.value
  });
};

const pathNameHistory = computed(() => {
  const optionQuery = Number(router.currentRoute.value.query.option);
  return optionQuery === 1
    ? 'total-users'
    : optionQuery === 2
    ? 'users-by-store'
    : 'account-permission';
});
const nameHistory = computed(() => {
  const optionQuery = Number(router.currentRoute.value.query.option);
  return optionQuery === 1
    ? t('common.total_users')
    : optionQuery === 2
    ? t('common.users_by_store')
    : t('common.account_permission');
});
const shopHistory = computed(() => {
  return router.currentRoute.value.query.shop;
});
const pageHistory = computed(() => {
  return router.currentRoute.value.query.page;
});
const backRouter = () => {
  const queryParams = [];
  if (shopHistory.value) {
    queryParams.push(`shop=${shopHistory.value}`);
  }

  if (pageHistory.value) {
    queryParams.push(`page=${pageHistory.value}`);
  }

  return `/user-management/${pathNameHistory.value}${
    queryParams.length > 0 ? `?${queryParams.join('&')}` : ''
  }`;
};

const goBackRouter = () => {
  router.back();
};
const routes = ref([
  {
    path: '/dashboard',
    breadcrumbName: t('common.home')
  },
  {
    path: '/store-management',
    breadcrumbName: t('common.stores_management')
  },
  {
    path: backRouter(),
    breadcrumbName: nameHistory.value
  },
  {
    path: `/user-management/${pathNameHistory.value}/user-details-${userDetails.value.status}?id=${userId.value}`,
    breadcrumbName: t('common.personal_info')
  }
]);
const handleFileInputChange = (event) => {
  try {
    loadingUploadImage.value = true;
    const selectedFile = event.target.files[0];
    avatar.photoFile = selectedFile;
    const imageUrl = URL.createObjectURL(selectedFile);

    // if (document.getElementById('img-avatar')) {
    //   document.getElementById('img-avatar').src = imageUrl;
    // }
    handleCropper({
      imageState: avatar,
      imageSrc: imageUrl,
      ratio: 1,
      index: 0
    });
    avatarSrc.value = imageUrl;

    loadingUploadImage.value = false;
  } catch (e) {
    throw new Error(e.message);
  } finally {
    setTimeout(() => {
      loadingUploadImage.value = false;
    }, 2000);
  }
};
const handleUpdateImage = () => {
  if (avatar && avatar.photoFile) {
    const formData = new FormData();
    formData.append('avatar', avatar.photoFile);
    store.dispatch('updateUserAvatar', {
      userId: userId.value,
      userInfo: formData,
      token: getToken()
    });
  }
};
// if have avatar open and continue edit else choose new avatar
const handleUploadImage = () => {
  if (!avatar.photoImage) {
    document.getElementById('user_detail_avatar_upload')?.click();
  } else {
    handleCropper({
      imageState: avatar,
      imageSrc: avatar.photoImage,
      ratio: 1,
      index: 0
    });
  }
};
// const updateEnableLive = () => {
//   store.dispatch('enableLive', {
//     users: [
//       {
//         user_id: userId.value,
//         enableLive: true
//       }
//     ],
//     onUpdateSuccess: () => {
//       showAlert(
//         'success',
//         localStorage.getItem('lang') === 'en'
//           ? 'Account successfully opened.'
//           : 'アカウントが正常に開放されました',
//         'top-center'
//       );
//     },
//     token: getToken()
//   });
// };
const handleShowHidePasswordField = () => {
  isShowPasswordInputField.value = !isShowPasswordInputField.value;
};
const handleDisableAccount = () => {
  addModal(ConfirmModal, {
    onConfirm: () => {
      loadCallApi.value = true;
      store.dispatch('disableUser', {
        userId: userId.value,
        token: getToken(),
        onOffLoading: () => {
          loadCallApi.value = false;
        },
        onSuccess: () => {
          // store.dispatch('getAllUsers', {
          //   token: getToken()
          // });
          // setTimeout(() => {
          //   router.replace(
          //     `/user-management/${pathNameHistory.value}/user-details-disable?option=${router.currentRoute.value.query.option}&id=${userId.value}`
          //   );
          // }, 2000);
          // page=1&shop_tab=2&user_tab=disable&option=1&id=6731ad11ca14ce38b190de69
          setTimeout(() => {
            router.replace(
              `/store-management-active?shop_tab=2&user_tab=disable&option=${router.currentRoute.value.query.option}&id=${userId.value}`
            );
          }, 2000);
          loadCallApi.value = false;
        }
      });
    },
    title: t('common.do_you_want_hide_account'),
    textBtnClose: t('common.cancel_delete'),
    textBtnConfirm: t('common.confirm')
  });
};
const handleDeleteAvatar = (e) => {
  avatar.photoFile = null;
  avatar.photoImage = '';
  e.stopPropagation();
  document.getElementById('img-avatar').src = '';
  store.dispatch('deleteUserAvatar', {
    userId: userId.value,
    userInfo: {
      deleteAvatar: 1
    },
    token: getToken(),
    turnOffLoading: () => {
      loadingUploadImage.value = false;
    }
  });
};
const handleUpdateUserInfo = () => {
  const userData = {
    userId: userId.value,
    userInfo: {
      nickName: userDetails.value.nickName,
      userName: userDetails.value.userName
    },
    token: getToken()
  };
  store.dispatch('updateUserInfo', userData);
  // handleUpdateImage();
};

const getImageCropper = ({ imageState, index }) => {
  return (fileImageCropper, blobImageCropper) => {
    // update directly avatar here
    avatar.photoImage = blobImageCropper;
    if (isRef(imageState)) {
      imageState.value[index].photoFile = fileImageCropper;
      imageState.value[index].photoImage = blobImageCropper;
    } else {
      imageState.photoFile = fileImageCropper;
      imageState.photoImage = blobImageCropper;
    }
    handleUpdateImage();
  };
};

const handleUnBlockUserLive = () => {
  addModal(ConfirmEnableLive, {
    unblockType: true,
    onConfirm: () => {
      store.dispatch('enableLive', {
        users: [
          {
            user_id: userId.value,
            enableLive: true
          }
        ],

        onUpdateSuccess: () => {
          showAlert(
            'success',
            localStorage.getItem('lang') === 'en'
              ? 'Account successfully opened.'
              : 'アカウントが正常に開放されました',
            'top-center'
          );
          store.dispatch('getUserDetails', {
            userId: userId.value,
            token: getToken()
          });
          setTimeout(() => {
            router.replace(
              `/store-management-active?page=1&shop_tab=2&user_tab=active&option=${router.currentRoute.value.query.option}&id=${userId.value}`
            );
          }, 2000);
        },
        token: getToken()
      });
    }
  });
};
const updateEnableLive = () => {
  addModal(ConfirmEnableLive, {
    onConfirm: () => {
      store.dispatch('enableLive', {
        users: [
          {
            user_id: userId.value,
            enableLive: false
          }
        ],
        onUpdateSuccess: () => {
          showAlert(
            'success',
            localStorage.getItem('lang') === 'en'
              ? 'Account successfully blocked.'
              : '配信を禁止されました。',
            'top-center'
          );
          store.dispatch('getUserDetails', {
            userId: userId.value,
            token: getToken()
          });
          setTimeout(() => {
            router.replace(
              `/store-management-active?page=1&shop_tab=2&user_tab=active&option=${router.currentRoute.value.query.option}&id=${userId.value}`
            );
          }, 2000);
        },
        token: getToken()
      });
    }
  });
};
const handleDeletePermanentlyAccount = () => {
  addModal(ConfirmModal, {
    onConfirm: () => {
      loadCallApi.value = true;
      store.dispatch('deletePermanentlyUsers', {
        ids: [userId.value],
        token: getToken(),
        onOffLoading: () => {
          loadCallApi.value = false;
        },
        onSuccess: () => {
          store.dispatch('getAllUsers', {
            token: getToken()
          });
          setTimeout(() => {
            router.back();
          }, 2000);
          loadCallApi.value = false;
        }
      });
    },
    title: t('common.do_you_want_to_permanently_delete_this_account'),
    textBtnClose: t('common.cancel_delete'),
    textBtnConfirm: t('common.confirm'),
    action: 'delete'
  });
};
watch(userId, () => {
  if (userId.value) {
    store.dispatch('getUserDetails', {
      userId: userId.value,
      token: getToken()
    });
  }
});
watch(userDetails, () => {
  avatar.photoImage = userDetails.value.avatar
    ? getAvatarUserByName(userDetails.value.avatar)
    : '';
});
onMounted(() => {
  userId.value = route.query.id;
  store.dispatch('getShopDetails', {
    shopId: route.query.shop,
    navigationError: () => {
      // router.replace('/store-management');
    }
  });
  setTimeout(() => {
    loading.value = false;
  }, 3000);
});
</script>
<style lang="scss"></style>
