import { showAlert } from '../../helpers';

export const shopsMutation = {
  GET_ALL_SHOPS_ALL_STATUS(state, payload) {
    state.allShopsAllStatus = payload;
  },
  GET_ALL_SHOPS_ALL_STATUS_WITH_REPORT_DAILY(state, payload) {
    state.allShopsAllStatusWithReportDaily = payload;
  },
  GET_ALL_SHOPS(state, payload) {
    state.allShops = payload;
  },
  GET_SHOP_DETAILS(state, payload) {
    state.shopDetails = payload;
  },
  GET_SHOP_DETAILS_ERROR(state, payload) {
    payload.onError();
  },
  CLEAR_SHOP_DETAILS(state) {
    state.shopDetails = {};
  },
  DELETE_SHOP_SUCCESS(state, payload) {
    state.allShopsAllStatus = state.allShopsAllStatus.map((shop) => {
      return shop._id === payload.shopId
        ? { ...shop, status: 'disable' }
        : shop;
    });
    showAlert(
      'success',
      localStorage.getItem('lang') === 'en'
        ? 'Deleted shop successfully'
        : '店舗の削除に成功しました',
      'top-center'
    );
    setTimeout(() => {
      payload.navigation();
    }, 2000);
  },
  DELETE_SHOP_ERROR(state, payload) {
    showAlert(
      localStorage.getItem('lang') === 'en'
        ? 'Deleted shop failed'
        : '店舗の削除に失敗しました'
    );
  },
  DELETE_PERMANENTLY_SHOP_SUCCESS(state, payload) {
    state.allShopsAllStatus = state.allShopsAllStatus.filter(
      (shop) => shop._id !== payload.shopId
    );
    showAlert(
      'success',
      localStorage.getItem('lang') === 'en'
        ? 'The shop has been permanently deleted successfully.'
        : '店舗が永久に正常に削除されました',
      'top-center'
    );
    setTimeout(() => {
      payload.navigation();
    }, 2000);
  },
  DELETE_PERMANENTLY_SHOP_ERROR(state, payload) {
    showAlert(
      localStorage.getItem('lang') === 'en'
        ? 'The shop has failed to be permanently deleted.'
        : '店舗の永久削除に失敗しました'
    );
  },
  RESTORE_SHOP_SUCCESS(state, payload) {
    state.allShopsAllStatus = state.allShopsAllStatus.map((shop) => {
      return shop._id === payload.shopId ? { ...shop, status: 'active' } : shop;
    });
    showAlert(
      'success',
      localStorage.getItem('lang') === 'en'
        ? 'Deleted shop successfully'
        : '店舗の削除に成功しました',
      'top-center'
    );
    setTimeout(() => {
      payload.navigation();
    }, 2000);
  },
  RESTORE_SHOP_ERROR(state, payload) {
    showAlert(
      localStorage.getItem('lang') === 'en'
        ? 'Deleted shop failed'
        : '店舗の削除に失敗しました'
    );
  },
  UPDATE_SHOP_INFO_SUCCESS(state, payload) {
    showAlert(
      'success',
      localStorage.getItem('lang') === 'en'
        ? 'Updated shop information successfully'
        : '店舗情報が正常に更新されました',
      'top-center'
    );
    payload.reload();
  },
  UPDATE_SHOP_INFO_ERROR(state, payload) {
    showAlert(
      'error',
      localStorage.getItem('lang') === 'en'
        ? 'Updated shop information failed'
        : '店舗情報の更新に失敗しました',
      'top-center'
    );
  },
  GET_AREA_LIST(state, payload) {
    state.areaList = payload;
  },
  GET_SHOP_REQUEST_LIST(state, payload) {
    state.shopRequestList = payload;
  },
  GET_SHOP_REQUEST_INFO(state, payload) {
    state.shopRequestInfo = payload;
  },
  GET_SHOP_REQUEST_INFO_ERROR(state, payload) {
    state.shopRequestInfo = {};
  },
  CLEAR_SHOP_REQUEST_INFO(state, payload) {
    state.shopRequestInfo = {};
  },
  DELETE_REQUEST_SUCCESS(state, payload) {
    state.shopRequestList = state.shopRequestList.filter((shop) => {
      return shop._id !== payload.shopId;
    });
    showAlert(
      'success',
      localStorage.getItem('lang') === 'en'
        ? 'Request successfully deleted'
        : 'リクエストの削除に成功しました',
      'top-center'
    );
  },
  DELETE_REQUEST_ERROR(state, payload) {},
  CREATED_REQUEST_SUCCESS(state, payload) {
    state.shopRequestList = state.shopRequestList.filter((shop) => {
      return shop._id !== payload.shopId;
    });
    showAlert(
      'success',
      localStorage.getItem('lang') === 'en'
        ? 'Successfully created store'
        : '作成しました。',
      'top-center'
    );
    payload.onSuccess();
  },
  CREATED_REQUEST_ERROR(state) {
    showAlert(
      'error',
      localStorage.getItem('lang') === 'en'
        ? 'Email already exists.'
        : 'すでに登録されているメールアドレスです。',
      'top-center'
    );
  },
  GET_GIFT_POINT_DASHBOARD(state, payload) {
    state.dashboardGiftPointData = payload;
  },
  GET_LIVE_STREAMER_DASHBOARD(state, payload) {
    if (state.totalTimesOfLive.length == 0) {
      state.totalTimesOfLive = payload;
    }
    state.dashboardLiveStreamData = payload;
  },
  RESEND_EMAIL_SUCCESS(state) {
    const adminShop = state.shopDetails?.adminShop ?? {};
    state.shopDetails = {
      ...state.shopDetails,
      adminShop: {
        ...adminShop,
        firstLogin: 1
      }
    };
    showAlert(
      'success',
      localStorage.getItem('lang') === 'en'
        ? 'The confirmation email has been sent successfully.'
        : '確認メールが正常に送信されました。',
      'top-center'
    );
  },
  RESEND_EMAIL_ERROR() {
    showAlert(
      'error',
      localStorage.getItem('lang') === 'en'
        ? 'Failed to resend the confirmation email.'
        : '確認メールの再送信に失敗しました。',
      'top-center'
    );
  },
  GET_TOTAL_STREAMING_TIME_MODAL_SUCCESS(state, payload) {
    state.totalStreamingTimeModal = payload;
  },
  GET_TOTAL_STREAMING_DURATION_MODAL_SUCCESS(state, payload) {
    state.totalLiveStreamDurationModal = payload;
  },
  GET_GIFT_POINT_MODAL_SUCCESS(state, payload) {
    state.totalGiftPointModal = payload;
  }
};
