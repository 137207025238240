<template>
  <div class="form-add-user my-[42px] rounded-[12px]">
    <div class="title py-[12px] px-[20px] flex justify-between">
      <h2 class="form-name text-[20px] noto600">
        {{ $t('common.create_user') }}
      </h2>
      <button class="btn-close-form noto400 text-[13px]" v-on:click="onClose">
        {{ $t('common.close') }}
      </button>
    </div>
    <form id="f-add-user" class="inputs px-[20px] scroll-bar">
      <div class="box-inputs">
        <div class="name ip-style-all">
          <label for="name">{{ $t('common.name') }}</label>
          <div class="input-box">
            <input id="name" type="text" v-model="name" autocomplete="off" />
            <span class="line-left"></span>
          </div>
          <span class="line-bottom"></span>
        </div>
        <div class="user_id ip-style-all">
          <label for="user_id">{{ $t('common.user_id') }}</label>
          <div class="input-box">
            <input
              id="user_id"
              type="text"
              autocomplete="off"
              v-model="user_id"
            />
            <span class="line-left"></span>
          </div>
          <span class="line-bottom"></span>
        </div>
        <div class="pwd ip-style-all">
          <label for="password">{{ $t('common.password') }}</label>
          <div class="input-box">
            <input
              id="password"
              :type="showPassword ? 'text' : 'password'"
              :autocomplete="password"
              v-model="password"
              style="padding-right: 50px"
            />
            <span
              :class="{
                'absolute right-[10px] top-1/2 -translate-y-1/2 cursor-pointer': true,
                eye: showPassword
              }"
              v-html="PasswordShowIcon"
              v-on:click="showOrHidePassWord"
            ></span>
            <span class="line-left"></span>
          </div>
          <span class="line-bottom"></span>
        </div>
        <div class="avatar ip-style-all">
          <label for="avatar">{{ $t('common.avatar') }}</label>
          <div
            :class="{
              'input-upload-fake cursor-pointer': true,
              error: !inputCheck.avatar
            }"
            :style="{
              pointerEvents: loadingUploadImage ? 'none' : 'auto',
              userSelect: loadingUploadImage ? 'none' : 'auto',
              opacity: loadingUploadImage ? '.5' : '1'
            }"
            id="input-upload-fake"
            v-on:click="handleClickUploadIcon"
          >
            <span
              v-if="!loadingUploadImage && avatar.photoImage === ''"
              v-html="IconPlus"
              class="mt-[21.5px]"
            ></span>
            <span
              v-if="!loadingUploadImage && avatar.photoImage === ''"
              class="noto300 text-[11px]"
              >{{ $t('common.upload') }}</span
            >
            <a-spin v-if="loadingUploadImage" class="absolute top-[50%]" />
          </div>
          <div class="input-box hidden">
            <input
              id="ip-file-upload"
              accept="image/*"
              type="file"
              @change="handleFileInputChange"
              class="hidden"
            />
          </div>
        </div>
      </div>
    </form>
    <div class="button py-[12px] px-[20px] flex justify-center">
      <button
        :class="`fill-primary-text-white flex gap-[5px] ${
          processSaveUser && `disabled`
        } `"
        v-on:click="handleSubmit"
      >
        <span v-html="SaveIcon"></span>
        <span>{{ $t('common.save') }}</span>
        <span class="flex justify-center items-center" v-if="processSaveUser">
          <a-spin :indicator="indicator" />
        </span>
      </button>
    </div>
  </div>
  <ImageCropper
    :show="showImageCropper"
    :imageSrc="imageSrcCropper"
    :ratioCropper="ratioCropper"
    :onClose="hideImageCropper"
    :callBack="callBackImageCropper"
  />
</template>
<script setup>
import { LoadingOutlined } from '@ant-design/icons-vue';
import 'dayjs/locale/zh-cn';
import { computed, h, isRef, onMounted, reactive, ref, watch } from 'vue';
import { useStore } from 'vuex';
import {
  IconPlus,
  PasswordShowIcon,
  SaveIcon
} from '../../../../assets/constant/svg.js';
import {
  clearCharactersNotSupported,
  containsNonWhitespaceCharacters,
  removeAllWhitespace,
  showAlert,
  useDebounce,
  validateEmail,
  validatePhoneNumber
} from '../../../../helpers';
import { getToken } from '../../../../helpers/token.js';
import { useRoute } from 'vue-router';
import ImageCropper from '../../../master/ImageCropper.vue';

const store = useStore();
const route = useRoute();
const props = defineProps({
  onClose: {
    type: Function,
    required: true
  }
});
const processSaveUser = ref(false);
const language = localStorage.getItem('lang');
const showPassword = ref(false);
const name = ref('');
const user_id = ref('');
const password = ref('');
const loadingUploadImage = ref(false);

const avatar = reactive({
  photoFile: null,
  photoImage: ''
});

const showImageCropper = ref(false);
const imageSrcCropper = ref('');
const ratioCropper = ref(1);
const callBackImageCropper = ref(null);

const hideImageCropper = () => {
  showImageCropper.value = false;
};

const getImageCropper = ({ imageState, index }) => {
  return (fileImageCropper, blobImageCropper) => {
    // update directly avatar here
    avatar.photoImage = blobImageCropper;
    if (isRef(imageState)) {
      imageState.value[index].photoFile = fileImageCropper;
      imageState.value[index].photoImage = blobImageCropper;
    } else {
      imageState.photoFile = fileImageCropper;
      imageState.photoImage = blobImageCropper;
    }
    document.getElementById(
      'input-upload-fake'
    ).style.backgroundImage = `url('${avatar.photoImage}')`;
  };
};

const handleCropper = ({ imageState, imageSrc, ratio, index }) => {
  imageSrcCropper.value = imageSrc;
  ratioCropper.value = ratio;
  callBackImageCropper.value = getImageCropper({ imageState, index });
  showImageCropper.value = true;
};

const indicator = h(LoadingOutlined, {
  style: {
    fontSize: '30px',
    color: 'rgba(239, 104, 32, 1)'
  },
  spin: true
});
const userLogin = computed(() => store.state.userInfo ?? null);
const userDetails = computed(() => store.state.userDetails);

const selectedShop = computed(() => route.query.id ?? null);
const inputCheck = ref({
  name: true,
  user_id: true,
  password: true,
  avatar: true
});
const showOrHidePassWord = () => {
  showPassword.value = !showPassword.value;
};
const handleClickUploadIcon = () => {
  if (!loadingUploadImage.value) {
    document.getElementById('ip-file-upload').click();
  }
};
const handleFileInputChange = (event) => {
  try {
    loadingUploadImage.value = true;
    const selectedFile = event.target.files[0];
    avatar.value = selectedFile;
    const imageUrl = URL.createObjectURL(selectedFile);
    inputCheck.value.avatar = true;
    // setTimeout(() => {
    //   document.getElementById(
    //     'input-upload-fake'
    //   ).style.backgroundImage = `url('${imageUrl}')`;
    //   loadingUploadImage.value = false;
    // }, 2000);

    handleCropper({
      imageState: avatar,
      imageSrc: imageUrl,
      ratio: 1,
      index: 0
    });
  } catch (e) {
    throw new Error(e.message);
  } finally {
    setTimeout(() => {
      loadingUploadImage.value = false;
    }, 2000);
  }
};
const validateValueInForm = (
  ref,
  id,
  typeEmail = false,
  typePhoneNumber = false
) => {
  if (typeEmail) {
    inputCheck.value[id] = validateEmail(ref);
  } else if (typePhoneNumber) {
    inputCheck.value[id] = validatePhoneNumber(ref);
  } else {
    inputCheck.value[id] =
      containsNonWhitespaceCharacters(ref) && ref !== null && ref !== undefined;
  }
};
const arrDebounce = Object.keys(inputCheck.value)?.map(() => {
  return useDebounce(validateValueInForm, 500);
});
watch(
  [name, user_id, password, avatar],
  (
    [newName, newUser_id, newPassword, newAvatar],
    [oldName, oldUser_id, oldPassword, oldAvatar]
  ) => {
    if (newName !== oldName) {
      name.value = newName?.trimStart();
      arrDebounce[0](newName?.trimStart(), 'name');
    } else if (newUser_id !== oldUser_id) {
      user_id.value = clearCharactersNotSupported(newUser_id);
      arrDebounce[1](clearCharactersNotSupported(newUser_id), 'user_id');
    } else if (newPassword !== oldPassword) {
      password.value = clearCharactersNotSupported(newPassword);
      arrDebounce[2](clearCharactersNotSupported(newPassword), 'password');
    } else if (newAvatar !== oldAvatar) {
      arrDebounce[3](newAvatar, 'avatar');
    }
  }
);
const validateBeforeSubmit = () => {
  let messageError = '';

  if (loadingUploadImage.value) {
    messageError =
      messageError === ''
        ? language === 'en'
          ? 'Uploading photos in progress'
          : '写真をアップロード中です'
        : messageError;
  }
  if (messageError !== '') {
    showAlert('error', messageError, 'top-center');
  }
  return messageError === '';
};

const handleSubmit = () => {
  if (
    validateBeforeSubmit() &&
    !processSaveUser.value &&
    !loadingUploadImage.value
  ) {
    processSaveUser.value = true;
    const formData = new FormData();
    formData.append('nickName', name.value);
    formData.append('userName', user_id.value);
    formData.append('password', password.value);
    formData.append('gender', 'other');
    if (avatar.value != '') {
      formData.append('avatar', avatar.photoFile);
    }
    formData.append('role', 'streamer');
    formData.append('orderBy', 1);
    formData.append('status', 'active');
    formData.append('statusOld', 'active');
    formData.append('shop', selectedShop.value);
    store.dispatch('saveUserInfo', {
      userData: formData,
      token: getToken(),
      onCloseForm: () => {
        props.onClose();
      },
      onTurnOffLoad: () => {
        setTimeout(() => {
          processSaveUser.value = false;
        }, 1000);
      }
    });
  }
};
onMounted(() => {
  name.value = '';
  user_id.value = '';
  password.value = '';
});
</script>
<style lang="scss" scoped>
.form-add-user {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  width: 46%;
  max-height: calc(100% - 48px);
  background-color: var(--brg-light-color);
  overflow: hidden;

  .title {
    width: 100%;
    height: 54px;
    border-bottom: 1px solid var(--line-color-light);

    .btn-close-form {
      color: rgba(102, 112, 133, 1);
    }
  }

  .inputs {
    flex: 1;
    width: 100%;
    border-bottom: 1px solid var(--line-color-light);
    overflow-y: auto;
    overflow-x: hidden;

    .box-inputs {
      width: 100%;
      height: auto;
      display: flex;
      justify-content: flex-start;
      align-items: flex-start;
      flex-direction: column;
      gap: 24px;
      padding: 20px 0px;

      .eye::after {
        position: absolute;
        content: '';
        top: 50%;
        right: 50%;
        width: 25px;
        height: 2px;
        transform: translateX(50%) translateY(-50%) rotate(45deg);
        background-color: var(--orange-color);
      }

      .ip-style-all {
        position: relative;
        display: flex;
        justify-content: space-around;
        align-items: flex-start;
        flex-direction: column;
        gap: 6px;
        width: 100%;

        label {
          font-family: 'Noto400';
          font-size: 14px;
          height: max-content;
          color: rgba(52, 64, 84, 1);
        }

        .ant-picker-focused {
          box-shadow: 0 0 0 2px rgba(5, 145, 255, 0.2);
        }

        .input-box {
          position: relative;
          flex: 1;
          width: 100%;

          input,
          .input {
            width: 100%;
            height: 44px;
            padding: 10px 14px;
            outline: none;
            border: 1px solid var(--line-color-light);
            border-radius: 8px;
            font-size: 16px;
            &:focus {
              background-color: #f2f4f7;
            }
          }
        }

        .line-bottom {
          position: absolute;
          left: 0px;
          bottom: -12px;
          width: 100%;
          height: 1px;
          background-color: var(--line-color-light);
        }
      }

      .avatar {
        .input-upload-fake {
          display: flex;
          justify-content: flex-start;
          align-items: center;
          flex-direction: column;
          background-position: center;
          background-size: cover;
          gap: 5px;
          width: 80px;
          height: 80px;
          border-radius: 6px;
          border: 1px dashed rgba(217, 217, 217, 1);

          span {
            color: rgba(102, 102, 135, 1);
          }
        }
      }
    }
  }

  .button {
    width: 100%;
    height: 68px;

    .fill-primary-text-white {
      width: 100%;
    }
  }

  button.disabled {
    background-color: rgb(214 159 125) !important;
  }
}

@media only screen and (max-width: 767px) {
  .form-add-user {
    width: 70vw;
  }
}
</style>
