import axiosConfig from '../../config/axiosConfig';
import { getToken } from '../../helpers/token';

export const usersAction = {
  async saveUserInfo(context, data) {
    try {
      const res = await axiosConfig({
        method: 'post',
        url: `/user-of-shop`,
        token: getToken(),
        data: data?.userData
      });
      if (
        (res.status === 200 || res.status === 201) &&
        !res.data.errorResponse &&
        !res.data.response
      ) {
        context.commit('SAVE_USER_INFO', res.data);
        data.onTurnOffLoad();
        data.onCloseForm();
      } else {
        context.commit('SAVE_USER_INFO_ERROR', res.data);
      }
    } catch (error) {
      throw new Error(error);
    } finally {
      data.onTurnOffLoad();
    }
  },

  async getUserLive(context, data) {
    try {
      const res = await axiosConfig({
        method: 'get',
        url: `channel?channelName=general`,
        token: data.token
      });
      if (res?.status === 200 || res?.status === 201) {
        context.commit('GET_USER_LIVE', res.data);
      }
    } catch (error) {
      throw new Error(error);
    }
  },

  async getAllUsers(context, data) {
    try {
      const res = await axiosConfig({
        method: 'get',
        url: 'user-of-shop?type=get_all&status[]=active&status[]=pending&status[]=disable',
        token: data.token
      });
      if (res?.status === 200 || res?.status === 201) {
        context.commit('GET_ALL_USERS', res.data);
      }
    } catch (error) {
      throw new Error(error);
    }
  },

  async getUserInShop(context, data) {
    try {
      const res = await axiosConfig({
        method: 'get',
        url: `/user-of-shop?shop=${data.shopId}&status[]=active&status[]=pending&status[]=disable`,
        token: data.token
      });
      context.commit('GET_USER_IN_SHOP', res.data);
    } catch (error) {
      throw new Error(error);
    }
  },

  async getUserDetails(context, data) {
    try {
      const res = await axiosConfig({
        method: 'get',
        url: `/user-of-shop/${data.userId}`,
        token: data.token
      });
      context.commit('GET_USER_DETAILS', res.data);
    } catch (error) {
      context.commit('GET_USER_DETAILS', {});
      throw new Error(error);
    }
  },

  async allowPermission(context, data) {
    try {
      const res = await axiosConfig({
        method: 'put',
        url: `/purchased-packages/update-package-user`,
        data: data.data,
        token: data.token
      });
      if (res.status === 200 || res.status === 201) {
        context.commit('ALLOW_PERMISSION', data);
      } else {
        context.commit('ALLOW_PERMISSION_ERROR', res.data);
      }
    } catch (error) {
      throw new Error(error);
    }
  },

  async allowPermissionWhenActive(context, data) {
    try {
      const res = await axiosConfig({
        method: 'put',
        url: `/purchased-packages/update-package-user`,
        data: data.data,
        token: data.token
      });
      if (res.status === 200 || res.status === 201) {
        context.commit('ALLOW_PERMISSION_WHEN_ACTIVE_SUCCESS', data);
      } else {
        context.commit('ALLOW_PERMISSION_WHEN_ACTIVE_ERROR', res.data);
      }
    } catch (error) {
      throw new Error(error);
    } finally {
      data.onTurnOffLoadApi();
    }
  },

  async restoreUser(context, data) {
    try {
      const res = await axiosConfig({
        method: 'put',
        url: `/user-of-shop/restore/${data.userId}`,
        token: data.token
      });
      if (res.status === 200 || res.status === 201) {
        context.commit('RESTORE_USER', {
          onSuccess: data.onSuccess
        });
      }
    } catch (error) {
      context.commit('RESTORE_USER_ERROR', {});
    } finally {
      data.onOffLoading();
    }
  },

  async deleteUser(context, data) {
    try {
      const res = await axiosConfig({
        method: 'delete',
        url: `/user-of-shop/${data.userId}`,
        token: data.token
      });
      res.status === 200
        ? context.commit('DELETE_USER', {
            onSuccess: data.onSuccess
          })
        : context.commit('DELETE_USER_ERROR');
    } catch (error) {
      throw new Error(error);
    } finally {
      data.onOffLoading();
    }
  },

  async enableLive(context, data) {
    try {
      const res = await axiosConfig({
        method: 'post',
        url: `/user/update-enablelive`,
        token: data.token,
        data: {
          users: data.users
        }
      });
      if (res.status === 200 || res.status === 201) {
        context.commit('ENABLE_LIVE', res.data[0]?._id);
        if (data?.onUpdateSuccess()) {
          data?.onUpdateSuccess();
        }
      }
    } catch (error) {
      throw new Error(error);
    }
  },

  clearUserLive(context) {
    context.commit('CLEAR_USER_LIVE');
  },

  async disableUser(context, data) {
    try {
      const res = await axiosConfig({
        method: 'put',
        url: `/user-of-shop/disable-user/${data.userId}`,
        token: data.token
      });
      res.status === 200
        ? context.commit('DISABLE_USER', {
            onSuccess: data.onSuccess
          })
        : context.commit('DISABLE_USER_ERROR');
    } catch (error) {
      throw new Error(error);
    } finally {
      data.onOffLoading();
    }
  },

  async deletePermanentlyUsers(context, data) {
    try {
      const res = await axiosConfig({
        method: 'delete',
        url: `/user-of-shop/delete-forever`,
        data: { ids: data.ids },
        token: data.token
      });
      res.status === 200 || res.status === 201
        ? context.commit('DELETE_PERMANENTLY_USERS', {
            onSuccess: data.onSuccess
          })
        : context.commit('DELETE_PERMANENTLY_USERS_ERROR');
    } catch (error) {
      throw new Error(error);
    } finally {
      data.onOffLoading();
    }
  },
  async updateUserInfo(context, data) {
    try {
      const res = await axiosConfig({
        method: 'put',
        url: `/user-of-shop/${data.userId}`,
        data: data.userInfo,
        token: getToken()
      });
      if (
        (res.status === 200 || res.status === 201) &&
        res.status !== 500 &&
        !res.data.errorResponse &&
        !res.data.response
      ) {
        context.commit('UPDATE_USER_INFO', res.data);
      } else {
        context.commit('UPDATE_USER_INFO_ERROR');
      }
    } catch (error) {
      context.commit('UPDATE_USER_INFO_ERROR');
    }
  },
  async updateUserAvatar(context, data) {
    try {
      const res = await axiosConfig({
        method: 'put',
        url: `/user-of-shop/${data.userId}`,
        data: data.userInfo,
        token: getToken()
      });
      res.status === 200
        ? context.commit('UPDATE_AVATAR_USER_INFO', res.data)
        : context.commit('UPDATE_USER_INFO_ERROR', res.data);
    } catch (error) {
      throw new Error(error);
    }
  },
  async deleteUserAvatar(context, data) {
    try {
      const res = await axiosConfig({
        method: 'put',
        url: `/user-of-shop/${data.userId}`,
        data: data.userInfo,
        token: data.token
      });
      res.status === 200
        ? context.commit('DELETE_USER_AVATAR', res.data)
        : context.commit('DELETE_USER_AVATAR_ERROR', res.data);
      data.turnOffLoading();
    } catch (error) {
      throw new Error(error);
    }
  },
  async updatePassword(context, action) {
    try {
      const res = await axiosConfig({
        url: `/user/reset-password-user`,
        method: 'post',
        token: action.token,
        data: action.data
      });
      if (res.status === 200 || res.status === 201) {
        context.commit('UPDATE_PASSWORD_USER', {
          onSuccess: action.onSuccess
        });
      } else {
        context.commit('UPDATE_PASSWORD_USER_ERROR');
      }
    } catch (error) {
      throw new Error(error);
    }
  }
};
