<template>
  <div
    class="login-form-container h-screen flex flex-col justify-center items-center w-[100vw]"
  >
    <div class="login-form-logo flex items-center justify-center flex-col">
      <span>{{ $t('common.store_user_management') }}</span>
      <div class="logo" v-html="LogoIcon"></div>
    </div>
    <div class="login-form flex mt-[40px] xs:mt-[20px]">
      <a-form
        :model="formState"
        autocomplete="off"
        @finish="onFinish"
        @onFinishFailed="onFinishFailed"
      >
        <div class="email-input mb-1">
          <div class="label text-black text-[14px] font-bold leading-5">
            {{ $t('common.email') }}
          </div>
          <a-form-item name="userName" class="mb-0 mt-2" :rules="rules.email">
            <a-input
              v-on:click="firstRender && handleRemoveFirstRender()"
              placeholder="user@gmail.com"
              aria-autocomplete="off"
              v-model:value="formState.userName"
              :class="{
                'text-input w-[480px] py-3 px-4 rounded-xl xs:w-[300px] hover:border-[#D0D5DD] focus:border-[#D0D5DD] focus:border-1 text-black': true,
                '!text-[#A0A0AB]': firstRender
              }"
            />
          </a-form-item>
        </div>
        <div class="passoword-input mt-6">
          <div class="label text-black text-[14px] font-bold leading-5">
            {{ $t('common.password') }}
          </div>
          <a-form-item
            name="password"
            :rules="rules.password"
            class="mb-0 mt-2"
          >
            <a-input
              v-on:click="firstRender && handleRemoveFirstRender()"
              v-model:value="formState.password"
              :type="isShowPassword ? 'text' : 'password'"
              aria-autocomplete="off"
              :placeholder="$t('common.password')"
              :class="{
                'text-input w-[480px] py-3 px-4 rounded-xl xs:w-[300px] hover:border-[#D0D5DD] focus:border-[#D0D5DD] focus:border-1 text-black': true,
                '!text-[#A0A0AB]': firstRender
              }"
            />
            <div class="icon-container" @click="handleShow">
              <span
                :class="['icon', { 'icon-active': isShowPassword }]"
                v-html="PasswordShowIcon"
              ></span>
            </div>
          </a-form-item>
          <div
            class="forgot-password-button text-xs text-[#667085] flex justify-between items-center mt-2"
          >
            <a-form-item
              class="remeber-button !mb-0 flex items-center"
              name="rememberMe"
            >
              <label class="flex items-center gap-2 cursor-pointer">
                <input type="checkbox" v-model="formState.rememberMe" />
                {{ $t('common.save_password') }}
              </label>
            </a-form-item>
          </div>
        </div>
        <div class="submit-button mt-4">
          <a-form-item>
            <button
              :disabled="loading ? true : false"
              html-type="submit"
              :class="{
                'flex justify-center items-center gap-[20px] w-full h-[47.6px] rounded-xl bg-[#EF6820] mt-2 noto600 text-white': true,
                '!bg-[#ffb486]': processApiLogin
              }"
            >
              <span> {{ $t('common.login') }}</span>
              <span
                class="flex justify-center items-center"
                v-if="processApiLogin"
              >
                <a-spin :indicator="indicator" />
              </span>
            </button>
          </a-form-item>
        </div>
      </a-form>
    </div>
  </div>
</template>
<script setup>
import { LoadingOutlined } from '@ant-design/icons-vue';
import { h, onMounted, reactive, ref } from 'vue';
import { useI18n } from 'vue-i18n';
import { useStore } from 'vuex';
import { LogoIcon, PasswordShowIcon } from '../../../assets/constant/constant';
import { validateEmail } from '../../../helpers';
import router from '../../../router/router';
const store = useStore();
const { t } = useI18n();

const processApiLogin = ref(false);
const firstRender = ref(true);
const isShowPassword = ref(true);
const indicator = h(LoadingOutlined, {
  style: {
    fontSize: '18px',
    color: 'white'
  },
  spin: true
});
const formState = reactive({
  userName: '',
  password: '',
  rememberMe: false
});
const handleRemoveFirstRender = () => {
  formState.userName = '';
  formState.password = '';
  isShowPassword.value = false;
  firstRender.value = false;
};
const rules = {
  email: [
    {
      required: true,
      message: t('common.input_login_email_alert'),
      trigger: 'blur'
    }
  ],
  password: [
    { required: true, message: t('common.input_login_password_alert') }
  ]
};
const onFinish = (values) => {
  const data = ref({});
  if (validateEmail(values.userName)) {
    data.value = {
      email: values.userName,
      password: values.password,
      rememberMe: values.rememberMe
    };
  } else {
    data.value = {
      userName: values.userName,
      password: values.password,
      rememberMe: values.rememberMe
    };
  }
  if (values.userName !== '' || values.password !== '') {
    processApiLogin.value = true;
    store.dispatch('login', {
      data: {
        ...data.value,
        role: 'master'
      },
      onTurnOffLoading: () => {
        processApiLogin.value = false;
      },
      onNaviLoginSuccess: () => {
        router.push('/dashboard');
      }
    });
  }
};

const handleShow = () => {
  isShowPassword.value = !isShowPassword.value;
};

onMounted(() => {});
</script>

<style lang="scss" scoped>
@import '../../../assets/css/responsive/media-queries.scss';
.text-input {
  position: relative;
}
.ant-input:focus {
  outline: 0 !important;
  -webkit-box-shadow: 0 0 0 0 black !important;
  box-shadow: 0 0 0 0 black !important;
}
.text-input {
  position: relative;
  input:focus {
    background: 2px solid black;
  }
}
.icon-container {
  position: absolute;
  top: 26%;
  right: 3%;
  cursor: pointer;
  .icon {
    position: relative;
    display: inline-block;
  }
  .icon::before {
    opacity: 0;
    content: '';
    position: absolute;
    top: 48%;
    left: 50%;
    width: 100%;
    height: 2px;
    background-color: var(--orange-color);
    transform: translate(-50%, -50%) rotate(45deg);
    transition: all 0.3s ease-in-out;
    @include xs {
      top: 50%;
      left: 50%;
      width: 100%;
    }
  }
  .icon-active::before {
    opacity: 1;
  }
}

.logo {
  @include xs {
    transform: scale(0.8);
  }
}
.forgot-password-button a:hover {
  color: #667085;
}

input[type='checkbox'] {
  width: 16px;
  height: 16px;
  cursor: pointer;
  accent-color: #ef6820;
}
input[type='checkbox']:checked {
  background-color: #ef6820;
  border: 2px solid #ef6820;
}
</style>
