<template>
  <div
    v-if="!layoutMobile"
    :id="`user-deleted-item-${user?._id}`"
    class="user-deleted-item relative p-[20px] rounded-[12px] min-w-[230px]"
  >
    <div
      class="p-[10px] w-[25px] h-[25px] flex justify-center items-center absolute top-[14.2px] right-[14.2px] cursor-pointer"
      v-on:click="handleChangeChecked"
    >
      <span v-html="checkedDelete || selectAll ? CheckedOrange : CircleGray"></span>
    </div>
    <div
      class="img w-[85%] flex justify-center items-center relative"
      v-on:click="handleClickSeeDetails"
    >
      <img
        class="w-[100px] h-[100px] rounded-lg object-cover"
        :src="getAvatarUserByName(props?.user?.avatar)"
        alt=""
        @load="handleImageLoad"
        @error="handleImageError"
        style="
          box-shadow: rgba(0, 0, 0, 0.05) 0px 6px 24px 0px,
            rgba(0, 0, 0, 0.08) 0px 0px 0px 1px;
        "
      />
      <a-spin
        v-if="loading"
        style="position: absolute; top: 50%; left: 50%; transform: translate(-50%, -50%)"
      />
    </div>
    <div
      class="name--account-name flex justify-center items-center flex-col pt-[12px] w-full"
      v-on:click="handleClickSeeDetails"
    >
      <h3 class="name w-full text-center text-[16px] noto600 pb-[4px]">
        {{ props?.user?.nickName }}
      </h3>
      <span class="account-name w-full text-center text-[14px] noto400 leading-[22px]">{{
        props?.user?.userName
      }}</span>
    </div>
  </div>
  <div
    v-else
    :id="`user-deleted-item-${user?._id}`"
    class="user-deleted-item relative p-[20px] rounded-[12px] min-w-[230px]"
  >
    <div
      class="p-[10px] w-[25px] h-[25px] flex justify-center items-center absolute top-[6px] right-[6px] cursor-pointer"
      v-on:click="handleChangeChecked"
    >
      <span v-html="checkedDelete || selectAll ? CheckedOrange : CircleGray"></span>
    </div>
    <div
      class="img w-[85%] flex justify-center items-center relative"
      v-on:click="handleClickSeeDetails"
    >
      <img
        class="min-w-[80px] max-w-[80px] h-[80px] rounded-lg object-cover"
        :src="getAvatarUserByName(props?.user?.avatar)"
        alt=""
        @load="handleImageLoad"
        @error="handleImageError"
        style="
          box-shadow: rgba(0, 0, 0, 0.05) 0px 6px 24px 0px,
            rgba(0, 0, 0, 0.08) 0px 0px 0px 1px;
        "
      />
      <a-spin
        v-if="loading"
        style="position: absolute; top: 50%; left: 50%; transform: translate(-50%, -50%)"
      />
    </div>
    <div
      class="name--account-name flex justify-center items-center flex-col pt-[12px] w-full"
      v-on:click="handleClickSeeDetails"
    >
      <h3 class="name w-full text-center text-[16px] noto600 pb-[4px]">
        {{ props?.user?.nickName }}
      </h3>
      <span class="account-name w-full text-center text-[14px] noto400 leading-[22px]">{{
        props?.user?.userName
      }}</span>
    </div>
  </div>
</template>
<script setup>
import { computed, ref, watch } from "vue";
import { useRouter } from "vue-router";
import { CheckedOrange, CircleGray } from "../../../../assets/constant/svg";
import { getAvatarUserByName, handleImageError } from "../../../../helpers";
import { useStore } from "vuex";
const router = useRouter();
const store = useStore();
const layoutMobile = computed(() => store.state.layoutMobile);
const loading = ref(true);
const checkedDelete = ref(false);
const props = defineProps({
  user: {
    type: Object,
    required: true,
  },
  selectAll: {
    type: Boolean,
    required: true,
  },
  handlePushOrDeleteAllowArray: {
    type: Function,
    required: true,
  },
});
const handleImageLoad = () => {
  loading.value = false;
};
const handleChangeChecked = () => {
  checkedDelete.value = !checkedDelete.value;
};
const handleClickSeeDetails = () => {
  router.push(`/extended-management/user-details-active?id=${props?.user?._id}`);
};
watch(checkedDelete, () => {
  if (checkedDelete.value) {
    props.handlePushOrDeleteAllowArray(props?.user?._id, true);
  } else {
    props.handlePushOrDeleteAllowArray(props?.user?._id, false);
  }
});
</script>
<style scoped lang="scss">
$number_item: 5;
$gap: 24px;
.user-deleted-item {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  height: 202px;
  width: calc(
    (100% / #{$number_item}) - (#{$gap} * (#{$number_item} - 1) / #{$number_item})
  );
  border: 1px solid var(--border-color);
  height: 202px;
  cursor: pointer;
  background-color: var(--brg-light-color);
  &:hover {
    box-shadow: rgba(0, 0, 0, 0.1) 0px 4px 12px;
  }
  &:active {
    box-shadow: rgba(0, 0, 0, 0.3) 0px 4px 12px;
  }
  .name,
  .account-name {
    display: -webkit-box;
    -webkit-line-clamp: 1;
    -webkit-box-orient: vertical;
    overflow: hidden;
    text-overflow: ellipsis;
    word-break: break-word;
    max-width: 180px;
  }
  .checkedDelete:checked {
    border-color: #ef6820 !important;
    background-color: #ef6820 !important;
  }
}
@media screen and (max-width: 700px) {
  .user-deleted-item{
    min-width: 150px !important;
  }
}
</style>
