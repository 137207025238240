<template>
  <div
    v-if="!layoutMobile"
    :class="{
      'stores-item border bg-white border-gray-border rounded-lg flex justify-start items-center gap-6 px-3 py-4 cursor-pointer': true
    }"
    @click="handleShopInfo"
  >
    <div class="avatar rounded-lg">
      <img
        :src="getAvatarShopByName(data?.avatar)"
        class="rounded-lg w-[60px] h-[60px] max-w-[60px] max-h-[60px] object-cover"
        @error="(e) => handleImageError(e, 'shop')"
      />
    </div>
    <div
      class="store-info flex flex-1 flex-col gap-y-2 w-full max-w-[90%] overflow-hidden"
    >
      <div
        class="store-name whitespace-nowrap text-[18px] text-ellipsis overflow-hidden noto700 text-header-bold"
      >
        {{ shortenString(data?.name, lengthCharacterNameStore) || '-' }}
      </div>
      <div
        class="user-in-store flex items-center gap-2 whitespace-nowrap text-gray600 text-[16px] noto400"
      >
        <span v-html="userIcon"></span>
        <!-- {{
            data?.userOfShops?.filter(
              (item) =>
                getStatusUser(item) !== 'created' &&
                getStatusUser(item) !== 'pending'
            )?.length ?? 0
          }} -->
        {{ data?.userOfShops?.length ?? 0 }}
        {{ $t('common.user') }}
      </div>
    </div>
    <div
      class="border-[1px] border-solid border-[#F38744] text-[#F38744] bg-white text-center noto500 text-[12px] leading-[22px] min-w-[56px] h-[24px] rounded-[8px] whitespace-nowrap"
      v-if="data?.status === 'active'"
    >
      {{ $t('common.under_contract') }}
    </div>
  </div>
  <div
    v-else
    :class="{
      'stores-item border bg-white border-gray-border rounded-lg flex justify-start items-center gap-6  px-3 py-4 cursor-pointer': true
    }"
    @click="handleShopInfo"
  >
    <div class="avatar rounded-lg">
      <img
        :src="getAvatarShopByName(data?.avatar)"
        class="rounded-lg w-[60px] h-[60px] max-w-[60px] max-h-[60px] object-cover"
        @error="(e) => handleImageError(e, 'shop')"
      />
    </div>
    <div class="store-info flex flex-1 flex-col gap-y-2">
      <div
        class="store-name max-w-full whitespace-nowrap text-[18px] noto700 text-header-bold text-ellipsis overflow-hidden"
      >
        {{ shortenString(data?.name, lengthCharacterNameStore) || '-' }}
      </div>
      <div
        class="user-in-store flex items-center gap-2 whitespace-nowrap text-gray600 text-[16px] noto400"
      >
        <span v-html="userIcon"></span>
        {{ data?.userOfShops?.length ?? 0 }} {{ $t('common.user') }}
      </div>
    </div>
    <div
      class="border-[1px] border-solid border-[#F38744] text-[#F38744] bg-white text-center noto500 text-[12px] leading-[22px] w-[56px] h-[24px] rounded-[8px] whitespace-nowrap"
      v-if="data?.status === 'active'"
    >
      {{ $t('common.under_contract') }}
    </div>
  </div>
</template>
  <script setup>
import { computed, onMounted, onUnmounted, ref } from 'vue';
import { useRouter } from 'vue-router';
import { userIcon } from '../../../../assets/constant/constant';
import {
  getAvatarShopByName,
  getStatusUser,
  handleImageError,
  shortenString
} from '../../../../helpers';
import { useStore } from 'vuex';
const store = useStore();
const router = useRouter();
const layoutMobile = computed(() => store.state.layoutMobile);
const lengthCharacterNameStore = ref(20);
const props = defineProps({
  data: Object,
  onClose: {
    type: Function,
    required: true
  }
});
const handleShopInfo = () => {
  props.onClose();
  router.push(`/store-management-${props.data.status}?id=${props.data._id}`);
};
const updateWindowWidth = () => {
  if (window.innerWidth > 1470) {
    lengthCharacterNameStore.value = 20;
  } else if (window.innerWidth > 1200) {
    lengthCharacterNameStore.value = 25;
  } else if (window.innerWidth > 700) {
    lengthCharacterNameStore.value = 100;
  } else {
    lengthCharacterNameStore.value = 17;
  }
};
onMounted(() => {
  updateWindowWidth();
  window.addEventListener('resize', updateWindowWidth);
});

onUnmounted(() => {
  window.removeEventListener('resize', updateWindowWidth);
});
</script>
<style lang="scss" scoped>
$two_item_on_row: 2;
$gap: 12px;
.stores-item {
  min-width: 180px;
  width: 100%;
  max-height: 84px;
  height: 100%;
  //   width: calc(
  //     (100% / #{$two_item_on_row}) -
  //       (#{$gap} * (#{$two_item_on_row} - 1) / #{$two_item_on_row})
  //   );
  @media screen and (max-width: 1200px) {
    width: 100%;
  }
}
</style>
  