<template>
  <div
    v-if="!layoutMobile"
    class="user-detail-active py-[21px] bg-[#f9fafb] w-full"
  >
    <div
      class="container mx-auto flex flex-col justify-start items-start gap-[20px]"
    >
      <div class="title flex justify-start items-center gap-[8px]">
        <button @click="goBackRouter" v-html="ArrowLeftNotLineHos"></button>
        <div
          class="btn-back flex justify-center items-center min-w-[88px] px-[10px] h-[36px] bg-[#FDEAD7] rounded-[6px]"
        >
          <span class="text-[#F38744] text-[16px] noto500 leading-[20px]">{{
            $t('common.personal_info')
          }}</span>
        </div>
      </div>
      <div
        class="content bg-white flex flex-col justify-start items-center gap-[27px] w-full min-h-[348px] px-[24px] py-[20px] border-solid border-[#D0D5DD] border-[1px] rounded-[12px]"
      >
        <div class="header w-full flex justify-between items-center">
          <h3 class="title noto700 text-[20px] leading-[28px] text-[#475467]">
            {{ $t('common.user_information') }}
          </h3>
          <div
            class="w-[120px] h-[42px] flex justify-center items-center gap-[4px] bg-white text-[#F04438] rounded-[8px] disabled border-[1px] border-solid border-[#F04438]"
          >
            <span v-html="UserStatusIcon"></span>
            <span>{{ $t('common.stop') }}</span>
          </div>
          <div class="btns flex justify-center items-center gap-[8px]">
            <button
              @click="handleActivateAccount"
              :disabled="loadCallApi"
              class="flex justify-center items-center bg-[#12B76A] min-w-[130px] h-[42px] rounded-[8px]"
            >
              <span class="text-white text-[14px] noto500 leading-[18px]">{{
                $t('common.unblock_live')
              }}</span>
            </button>
            <button
              @click="handleDeletePermanentlyAccount"
              class="flex justify-center items-center bg-white min-w-[130px] h-[42px] rounded-[8px] border-solid border-[1px] border-[#F04438]"
            >
              <span class="text-[#F04438] text-[14px] noto500 leading-[18px]">{{
                $t('common.account_deletion')
              }}</span>
            </button>
            <button
              v-if="status !== 'created'"
              :disabled="loadCallApi"
              @click="handleRestoreAccount"
              class="flex justify-center items-center min-w-[144px] h-[42px] bg-[#F38744] rounded-[8px]"
            >
              <span class="text-white text-[14px] noto500 leading-[18px]">{{
                $t('common.view_account')
              }}</span>
            </button>
          </div>
        </div>
        <div
          class="information-user w-full flex flex-col justify-start items-start gap-[20px]"
        >
          <div class="avatar w-full">
            <label class="flex justify-start items-center gap-[8px]">
              <span
                class="text-[16px] min-w-[200px] noto500 leading-[20px] text-[#667085] text-left"
                >{{ $t('common.profile_picture') }}</span
              >
              <div class="box-avatar flex justify-start items-center gap[8px]">
                <div class="upload-avatar relative w-[80px] h-[80px] mr-[16px]">
                  <img
                    class="w-[80px] h-[80px] object-cover rounded-lg grayscale-[1]"
                    :src="getAvatarUserByName(userDetails.avatar)"
                    @error="handleImageError"
                    alt=""
                  />
                  <button
                    class="flex justify-center items-center absolute bottom-1 right-1 w-[32px] h-[32px] bg-[#D0D5DD] rounded-full"
                  >
                    <span class="icon-camera" v-html="IconCamera"></span>
                  </button>
                </div>
                <button
                  disabled
                  class="delete-avatar bg-white min-w-[110px] px-[10px] h-[42px] flex justify-center items-center gap-[6px] rounded-[8px] border-[1px] border-solid border-[#ADB5C2]"
                >
                  <span class="icon-trash" v-html="TrashGrayIcon"></span>
                  <span
                    class="text-[#ADB5C2] text-[14px] noto500 leading-[18px]"
                    >{{ $t('common.delete_image') }}</span
                  >
                </button>
              </div>
            </label>
          </div>
          <span class="w-full h-[1px] bg-[#EAECF0]"> </span>
          <div class="user-id w-full">
            <label class="flex justify-start items-center gap-[8px]">
              <span
                class="min-w-[200px] text-[16px] noto500 leading-[20px] text-[#667085] text-left"
              >
                {{ $t('common.user_id_v2') }}
              </span>
              <input
                readonly
                v-model="userDetails.userName"
                class="flex-1 h-[42px] bg-[#F2F4F7] rounded-[8px] border-solid border-[1px] border-[#d0d5dd] outline-none px-[16px] text-[14px] noto400 leading-[18px]"
              />
            </label>
          </div>
          <span class="w-full h-[1px] bg-[#EAECF0]"></span>
          <div class="user-name w-full">
            <label class="flex justify-start items-center gap-[8px]">
              <span
                class="min-w-[200px] text-[16px] noto500 leading-[20px] text-[#667085] text-left"
              >
                {{ $t('common.username') }}
              </span>
              <input
                readonly
                v-model="userDetails.nickName"
                class="flex-1 h-[42px] bg-[#F2F4F7] rounded-[8px] border-solid border-[1px] border-[#d0d5dd] outline-none px-[16px] text-[14px] noto400 leading-[18px]"
              />
            </label>
          </div>
        </div>
      </div>
    </div>
  </div>
  <!-- MOBILE ONLY -->
  <div v-else class="user-detail-active bg-[#f9fafb] w-full">
    <!-- <div class="flex flex-col justify-start items-start gap-[15px]">
      <div class="title">
        <h2 class="noto700 text-[24px] leading-[30px] text-[#1D2939]">
          {{ $t('common.personal_info') }}
        </h2>
      </div>
      <div class="search-box">
        <Breadcrumd :routes="routes" />
      </div>
    </div> -->
    <div
      class="w-[100%] mx-auto flex flex-col justify-start items-start gap-[20px]"
    >
      <div class="title flex justify-start items-center gap-[8px]">
        <button @click="goBackRouter" v-html="ArrowLeftNotLineHos"></button>
        <div
          class="btn-back flex justify-center items-center min-w-[88px] px-[10px] h-[36px] bg-[#FDEAD7] rounded-[6px]"
        >
          <span class="text-[#F38744] text-[16px] noto500 leading-[20px]">{{
            $t('common.personal_info')
          }}</span>
        </div>
      </div>
      <div
        class="content bg-white flex flex-col justify-start items-center gap-[20px] w-full min-h-[348px] px-[24px] py-[20px] border-solid border-[#D0D5DD] border-[1px] rounded-[12px]"
      >
        <div
          class="header w-full flex flex-col justify-center items-center gap-[20px]"
        >
          <div
            class="w-[120px] h-[42px] flex justify-center items-center gap-[4px] bg-white text-[#F04438] rounded-[8px] disabled border-[1px] border-solid border-[#F04438]"
          >
            <span v-html="UserStatusIcon"></span>
            <span>{{ $t('common.stop') }}</span>
          </div>
          <h3 class="title noto700 text-[20px] leading-[28px] text-[#475467]">
            {{ $t('common.user_information') }}
          </h3>
        </div>
        <button
          v-if="userDetails.enableLive === false"
          :disabled="loadCallApi"
          @click="handleUnBlockUserLive"
          class="flex justify-center items-center bg-[#12B76A] w-full h-[42px] rounded-[8px]"
        >
          <span class="text-white text-[14px] noto500 leading-[18px]">{{
            $t('common.unblock_live')
          }}</span>
        </button>
        <span class="w-full h-[1px] bg-[#EAECF0]"></span>
        <div
          class="information-user w-full flex flex-col justify-start items-start gap-[20px]"
        >
          <div class="avatar w-full">
            <label class="flex flex-col justify-start items-start gap-[12px]">
              <span
                class="text-[16px] min-w-[200px] noto500 leading-[20px] text-[#667085] text-left"
                >{{ $t('common.profile_picture') }}</span
              >
              <div class="box-avatar flex justify-start items-center gap[8px]">
                <div class="upload-avatar relative w-[80px] h-[80px] mr-[16px]">
                  <img
                    class="w-[80px] h-[80px] object-cover rounded-lg grayscale-[1]"
                    :src="getAvatarUserByName(userDetails.avatar)"
                    @error="handleImageError"
                    alt=""
                  />
                  <button
                    class="flex justify-center items-center absolute bottom-0 right-0 w-[32px] h-[32px] bg-[#D0D5DD] rounded-lg"
                  >
                    <span class="icon-camera" v-html="IconCamera"></span>
                  </button>
                </div>
                <button
                  disabled
                  class="delete-avatar bg-white min-w-[32px] px-[10px] h-[32px] flex justify-center items-center gap-[6px] rounded-[8px] border-[1px] border-solid border-[#ADB5C2]"
                >
                  <span class="icon-trash" v-html="TrashGrayIcon"></span>
                </button>
              </div>
            </label>
          </div>
          <span class="w-full h-[1px] bg-[#EAECF0]"> </span>
          <div class="user-id w-full">
            <label class="flex flex-col justify-start items-start gap-[12px]">
              <span
                class="min-w-[200px] text-[16px] noto500 leading-[20px] text-[#667085] text-left"
              >
                {{ $t('common.user_id_v2') }}
              </span>
              <input
                readonly
                v-model="userDetails.userName"
                class="flex-1 min-h-[42px] w-full h-[42px] bg-[#F2F4F7] rounded-[8px] border-solid border-[1px] border-[#d0d5dd] outline-none px-[16px] text-[14px] noto400 leading-[18px]"
              />
            </label>
          </div>
          <span class="w-full h-[1px] bg-[#EAECF0]"></span>
          <div class="user-name w-full">
            <label class="flex flex-col justify-start items-start gap-[12px]">
              <span
                class="min-w-[200px] text-[16px] noto500 leading-[20px] text-[#667085] text-left"
              >
                {{ $t('common.username') }}
              </span>
              <input
                readonly
                v-model="userDetails.nickName"
                class="flex-1 min-h-[42px] w-full h-[42px] bg-[#F2F4F7] rounded-[8px] border-solid border-[1px] border-[#d0d5dd] outline-none px-[16px] text-[14px] noto400 leading-[18px]"
              />
            </label>
          </div>
        </div>
        <span class="w-full h-[1px] bg-[#EAECF0]"></span>
        <div
          class="btns w-full flex flex-wrap justify-center items-center gap-[12px]"
        >
          <button
            @click="handleActivateAccount"
            :disabled="loadCallApi"
            class="flex-1 flex justify-center items-center bg-[#12B76A] min-w-[130px] h-[42px] rounded-[8px]"
          >
            <span class="text-white text-[14px] noto500 leading-[18px]">{{
              $t('common.unblock_live')
            }}</span>
          </button>
          <button
            @click="handleDeletePermanentlyAccount"
            class="flex-1 flex flex-wrap justify-center items-center bg-white min-w-[130px] h-[42px] rounded-[8px] border-solid border-[1px] border-[#F04438]"
          >
            <span class="text-[#F04438] text-[14px] noto500 leading-[18px]">{{
              $t('common.account_deletion')
            }}</span>
          </button>
          <button
            :disabled="loadCallApi"
            @click="handleRestoreAccount"
            class="flex-1 flex justify-center items-center min-w-[144px] h-[42px] bg-[#F38744] rounded-[8px]"
          >
            <span class="text-white text-[14px] noto500 leading-[18px]">{{
              $t('common.view_account')
            }}</span>
          </button>
        </div>
      </div>
    </div>
  </div>
</template>
<script setup>
import { computed, onMounted, ref, watch } from 'vue';
import { useI18n } from 'vue-i18n';
import { useRoute, useRouter } from 'vue-router';
import { useStore } from 'vuex';
import {
  ArrowLeftNotLineHos,
  IconCamera,
  TrashGrayIcon,
  UserStatusIcon
} from '../../../assets/constant/svg';
import {
  addModal,
  handleImageError,
  getAvatarUserByName,
  showAlert
} from '../../../helpers';
import Breadcrumd from '../../master/Breadcrumd.vue';
import ConfirmModal from './components/ConfirmModal.vue';
import { getToken } from '../../../helpers/token';
import moment from 'moment';

const store = useStore();
const route = useRoute();
const router = useRouter();
const token = getToken();
const shopSelected = computed(() => route.query.shop);
const layoutMobile = computed(() => store.state.layoutMobile);
const { t } = useI18n();
const userId = ref('');
const loading = ref(true);
const loadCallApi = ref(false);
const status = ref('');
const plansPackage = computed(() => {
  const pricingList = store.state.pricingList ? store.state.pricingList : [];
  return pricingList.length > 0 ? pricingList[0] : null;
});

const userDetails = computed(() => {
  return store.state.userDetails;
});
const pathNameHistory = computed(() => {
  const optionQuery = Number(router.currentRoute.value.query.option);
  return optionQuery === 1
    ? 'total-users'
    : optionQuery === 2
    ? 'users-by-store'
    : 'account-permission';
});
const nameHistory = computed(() => {
  const optionQuery = Number(router.currentRoute.value.query.option);
  return optionQuery === 1
    ? t('common.total_users')
    : optionQuery === 2
    ? t('common.users_by_store')
    : t('common.account_permission');
});
const shopHistory = computed(() => {
  return router.currentRoute.value.query.shop;
});
const pageHistory = computed(() => {
  return router.currentRoute.value.query.page;
});
const backRouter = () => {
  const queryParams = [];
  if (shopHistory.value) {
    queryParams.push(`shop=${shopHistory.value}`);
  }

  if (pageHistory.value) {
    queryParams.push(`page=${pageHistory.value}`);
  }

  return `/user-management/${pathNameHistory.value}${
    queryParams.length > 0 ? `?${queryParams.join('&')}` : ''
  }`;
};
const routes = ref([
  {
    path: '/dashboard',
    breadcrumbName: t('common.home')
  },
  {
    path: '/user-management/total-users',
    breadcrumbName: t('common.user_management')
  },
  {
    path: backRouter(),
    breadcrumbName: nameHistory.value
  },
  {
    path: `/user-management/${pathNameHistory.value}/user-details-${userDetails.value.status}?id=${userId.value}`,
    breadcrumbName: t('common.personal_info')
  }
]);
const goBackRouter = () => {
  router.back();
};
const updateEnableLive = () => {
  store.dispatch('enableLive', {
    users: [
      {
        user_id: userId.value,
        enableLive: false
      }
    ],
    token: token
  });
};
const handleActivateAccount = () => {
  loadCallApi.value = true;
  store.dispatch('buyPricing', {
    pricingInfo: {
      title: plansPackage.value.title || '',
      amount: plansPackage.value.price || 0,
      number_user: 1,
      shop: shopSelected.value,
      note: plansPackage.value.note || '',
      status: plansPackage.value.status || '',
      type: plansPackage.value.type || '',
      days: plansPackage.value.days || 0,
      packagePrice: plansPackage.value._id || '',
      start_date: moment().format('YYYY-MM-DD'),
      end_date: moment().add(1, 'M').format('YYYY-MM-DD'),
      price: plansPackage.value.price,
      user: [userId.value]
    },
    onSuccess: () => {
      store.dispatch('allowPermissionWhenActive', {
        data: {
          userIds: [userId.value]
        },
        token: token,
        onTurnOffLoadApi: () => {
          loadCallApi.value = false;
        },
        onSuccess: () => {
          router.replace({
            query: {
              ...router.currentRoute.value.query,
              user_tab: 'active'
            }
          });
        }
      });
    }
  });
};
const handleRestoreAccount = () => {
  addModal(ConfirmModal, {
    onConfirm: () => {
      loadCallApi.value = true;
      store.dispatch('restoreUser', {
        userId: userId.value,
        token: getToken(),
        onOffLoading: () => {
          loadCallApi.value = false;
        },
        onSuccess: () => {
          store.dispatch('getAllUsers', {
            token: getToken()
          });
          setTimeout(() => {
            router.replace(
              `/store-management-active?page=1&shop_tab=2&user_tab=active&option=${router.currentRoute.value.query.option}&id=${userId.value}`
            );
          }, 2000);
          loadCallApi.value = false;
        }
      });
    },
    title: t('common.do_you_want_restore_account'),
    textBtnClose: t('common.cancel_delete'),
    textBtnConfirm: t('common.confirm')
  });
};
const handleUnBlockUserLive = () => {
  addModal(ConfirmEnableLive, {
    unblockType: true,
    onConfirm: () => {
      store.dispatch('enableLive', {
        users: [
          {
            user_id: userId.value,
            enableLive: true
          }
        ],
        onUpdateSuccess: () => {
          showAlert(
            'success',
            localStorage.getItem('lang') === 'en'
              ? 'Account successfully opened.'
              : 'アカウントが正常に開放されました',
            'top-center'
          );
          store.dispatch('getUserDetails', {
            userId: userId.value,
            token: getToken()
          });
          setTimeout(() => {
            router.replace(
              `/store-management-active?page=1&shop_tab=2&user_tab=disable&option=${router.currentRoute.value.query.option}&id=${userId.value}`
            );
          }, 2000);
        },
        token: getToken()
      });
    }
  });
};
const handleDeletePermanentlyAccount = () => {
  addModal(ConfirmModal, {
    onConfirm: () => {
      loadCallApi.value = true;
      store.dispatch('deletePermanentlyUsers', {
        ids: [userId.value],
        token: getToken(),
        onOffLoading: () => {
          loadCallApi.value = false;
        },
        onSuccess: () => {
          store.dispatch('getAllUsers', {
            token: getToken()
          });
          setTimeout(() => {
            router.back();
          }, 2000);
          loadCallApi.value = false;
        }
      });
    },
    title: t('common.do_you_want_to_permanently_delete_this_account'),
    textBtnClose: t('common.cancel_delete'),
    textBtnConfirm: t('common.confirm'),
    action: 'delete'
  });
};

watch(userId, () => {
  if (userId.value) {
    store.dispatch('getUserDetails', {
      userId: userId.value,
      token: getToken()
    });
  }
});
onMounted(() => {
  userId.value = route.query.id;
  status.value = route.query.user_tab;
  store.dispatch('getPricingList', shopSelected.value);
  store.dispatch('getShopDetails', {
    shopId: route.query.shop,
    navigationError: () => {
      // router.replace('/store-management');
    }
  });
  setTimeout(() => {
    loading.value = false;
  }, 3000);
});
</script>
<style lang="scss"></style>
