<template>
  <div
    v-if="!layoutMobile"
    :class="{
      'w-full border bg-white border-gray-border rounded-lg flex justify-start items-center gap-6 px-6 py-5 cursor-pointer': true,
      '!bg-[#EAECF0]': data?.status === 'disable'
    }"
    @click="handleShopInfo"
  >
    <div class="avatar">
      <img
        :src="getAvatarShopByName(data?.avatar)"
        class="rounded-md w-[80px] h-[80px] max-w-[80px] max-h-[80px] object-cover"
        @error="(e) => handleImageError(e, 'shop')"
      />
    </div>
    <div class="store-info flex flex-1 gap-y-3">
      <div class="w-[40%] overflow-hidden space-y-1">
        <div
          class="store-name whitespace-nowrap text-[18px] noto700 text-header-bold"
        >
          {{ shortenString(data?.name, lengthCharacterNameStore) || '-' }}
        </div>
        <div class="store-subscription-info w-full space-y-1">
          <div
            class="store-address flex items-center gap-2 text-gray600 w-full text-[16px] noto400 pr-3 overflow-hidden"
          >
            <span v-html="AddressIcon"></span>
            <span
              class="whitespace-nowrap overflow-hidden text-ellipsis block w-full"
            >
              {{ data?.address || '' }}
            </span>
          </div>
          <div
            class="user-in-store flex items-center gap-2 whitespace-nowrap text-gray600 text-[16px] noto400 -ml-1"
          >
            <span v-html="userIcon"></span>
            {{ data?.userOfShops?.length ?? 0 }} {{ $t('common.user') }}
          </div>
        </div>
      </div>
      <div class="total-livestream-time w-[30%] flex justify-between">
        <div class="h-[80px] w-[1px] bg-[#D0D5DD]"></div>
        <div class="flex h-full flex-col items-center justify-center">
          <div class="noto500 text-[16px] text-[#667085]">総ライブ時間</div>
          <div class="text-[#182230] noto700 text-[24px] text-center">
            {{ liveStreamingTimeAfterCalculate }}
          </div>
        </div>
        <div class="h-[80px] w-[1px] bg-[#D0D5DD]"></div>
      </div>
      <div
        class="total-point-of-shop w-[30%] flex justify-center items-center text-center"
      >
        <div>
          <div class="noto500 text-[16px] text-[#667085]">ポイント</div>
          <div class="text-[#182230] noto700 text-[24px]">
            {{ formatPoint(caclulateGiftPoint || 0) }}
          </div>
        </div>
      </div>
      <div class="h-[80px] w-[1px] bg-[#D0D5DD]"></div>
    </div>
    <div
      class="border-[1px] border-solid border-[#F38744] text-[#F38744] bg-white text-center noto500 text-[12px] leading-[22px] min-w-[56px] h-[24px] rounded-[8px] whitespace-nowrap"
      v-if="data?.status === 'active'"
    >
      {{ $t('common.under_contract') }}
    </div>
    <div v-else class="w-[56px] h-[24px]"></div>
  </div>
  <!-- mobile -->
  <div
    v-else
    :class="{
      'stores-item border bg-white border-gray-border rounded-lg flex justify-start items-center gap-6 px-6 py-5 cursor-pointer': true,
      '!bg-[#EAECF0]': data?.status === 'disable'
    }"
    @click="handleShopInfo"
  >
    <!-- avatar -->
    <!-- <div class="avatar border border-gray-border rounded-full">
      <img
        :src="getAvatarShopByName(data?.avatar)"
        class="rounded-full w-[80px] h-[80px] max-w-[80px] max-h-[80px] object-cover"
        @error="(e) => handleImageError(e, 'shop')"
      />
    </div> -->
    <!-- subscription -->
    <!-- <div class="store-info flex flex-1 flex-col gap-y-3">
      <div
        class="store-name whitespace-nowrap text-[18px] noto700 text-header-bold"
      >
        {{ shortenString(data?.name, lengthCharacterNameStore) || '-' }}
      </div>
      <div
        class="user-in-store flex items-center gap-2 whitespace-nowrap text-gray600 text-[16px] noto400"
      >
        <span v-html="userIcon"></span>
        {{ data?.userOfShops?.length ?? 0 }} {{ $t('common.user') }}
      </div>
    </div> -->
    <!-- footer -->
    <!-- <div
      class="border-[1px] border-solid border-[#F38744] text-[#F38744] bg-white text-center noto500 text-[12px] leading-[22px] w-[56px] h-[24px] rounded-[8px] whitespace-nowrap"
      v-if="data?.status === 'active'"
    >
      {{ $t('common.under_contract') }}
    </div> -->
    <div class="w-full flex flex-col items-center gap-2">
      <img
        :src="getAvatarShopByName(data?.avatar)"
        class="rounded-md w-[80px] h-[80px] max-w-[80px] max-h-[80px] object-cover"
        @error="(e) => handleImageError(e, 'shop')"
      />
      <div
        class="store-name whitespace-nowrap text-[18px] noto700 text-header-bold"
      >
        {{ shortenString(data?.name, lengthCharacterNameStore) || '-' }}
      </div>
      <div
        class="border-[1px] border-solid border-[#F38744] text-[#F38744] bg-white text-center noto500 text-[12px] leading-[22px] w-[56px] h-[24px] rounded-[8px] whitespace-nowrap"
        v-if="data?.status === 'active'"
      >
        {{ $t('common.under_contract') }}
      </div>
      <div class="self-start overflow-hidden">
        <div
          class="store-address flex items-center gap-2 text-gray600 text-[16px] noto400 pr-2 overflow-hidden whitespace-nowrap text-ellipsis"
        >
          <span v-html="AddressIcon"></span>
          {{ data?.address || '' }}
        </div>
        <div
          class="user-in-store flex items-center gap-2 whitespace-nowrap text-gray600 text-[16px] noto400 -ml-[5px]"
        >
          <span v-html="userIcon"></span>
          {{ data?.userOfShops?.length ?? 0 }} {{ $t('common.user') }}
        </div>
      </div>
      <!-- divide line -->
      <div class="w-full h-[1px] bg-[#D0D5DD]"></div>
      <!-- footer -->
      <div class="flex justify-between w-full items-center">
        <div class="noto500 text-[16px] text-[#667085]">総ライブ時間</div>
        <div class="text-[#182230] noto700 text-[24px]">
          {{ liveStreamingTimeAfterCalculate }}
        </div>
      </div>
      <div class="flex justify-between w-full items-center">
        <div class="noto500 text-[16px] text-[#667085]">ポイント</div>
        <div class="text-[#182230] noto700 text-[24px]">
          {{ formatPoint(caclulateGiftPoint || 0) }}
        </div>
      </div>
    </div>
  </div>
</template>
<script setup>
import { computed, onMounted, onUnmounted, ref } from 'vue';
import { useRouter } from 'vue-router';
import { userIcon } from '../../../../assets/constant/constant';
import {
  formatPoint,
  getAvatarShopByName,
  getStatusUser,
  handleImageError,
  shortenString
} from '../../../../helpers';
import { useStore } from 'vuex';
import { AddressIcon } from '../../../../assets/constant/svg';
import moment from 'moment';
const store = useStore();
const router = useRouter();
const layoutMobile = computed(() => store.state.layoutMobile);
const lengthCharacterNameStore = ref(20);
const props = defineProps({
  data: Object
});
const handleShopInfo = () => {
  router.push(`/store-management-${props.data.status}?id=${props.data._id}`);
};
const updateWindowWidth = () => {
  if (window.innerWidth > 1470) {
    lengthCharacterNameStore.value = 20;
  } else if (window.innerWidth > 1200) {
    lengthCharacterNameStore.value = 25;
  } else if (window.innerWidth > 700) {
    lengthCharacterNameStore.value = 100;
  } else {
    lengthCharacterNameStore.value = 17;
  }
};
const calculateLiveStreamTime = props.data?.liveStreamer?.reduce(
  (acc, item) => {
    const startDate = moment(item.startDate);
    const endDate = item.endDate ? moment(item.endDate) : moment();
    const duration = endDate.diff(startDate, 'seconds');
    return (acc += duration);
  },
  0
);

const liveStreamingTimeAfterCalculate = moment
  .utc(calculateLiveStreamTime || 0 * 1000)
  .format('HH:mm:ss');

const caclulateGiftPoint = props.data?.giftPoint?.reduce((acc, item) => {
  return (acc += item.points);
}, 0);
onMounted(() => {
  updateWindowWidth();
  window.addEventListener('resize', updateWindowWidth);
});

onUnmounted(() => {
  window.removeEventListener('resize', updateWindowWidth);
});
</script>
<style lang="scss" scoped>
$three_item_on_row: 3;
$two_item_on_row: 2;
$gap: 24px;
.stores-item {
  min-width: 180px;
  width: calc(
    (100% / #{$three_item_on_row}) -
      (#{$gap} * (#{$three_item_on_row} - 1) / #{$three_item_on_row})
  );
  @media screen and (max-width: 1700px) {
    width: calc(
      (100% / #{$two_item_on_row}) -
        (#{$gap} * (#{$two_item_on_row} - 1) / #{$two_item_on_row})
    );
  }
  @media screen and (max-width: 1200px) {
    width: 100%;
  }
}
</style>
