<template>
  <div ref="zoneSelectUser" v-if="!layoutMobile" class="box-select-status-user">
    <div class="btn-select relative min-w-[223px] min-h-[44px]">
      <button
        v-on:click="handleOpenOrCloseSelectedStatusUserFilter"
        class="min-w-[237px] h-[44px] rounded-[8px] border-solid border-[1px] border-[#D0D5DD] px-[20px] py-[12px] flex justify-center items-center gap-[12px] bg-white"
      >
        <span v-html="UserBlackIcon"></span>
        <span
          class="text-black noto500 text-[14px] leading-[18px] text-center"
          >{{ selectedStatusUserFilter?.name }}</span
        >
        <span
          class="max-w-[22px] min-w-[22px] min-w-[22px] max-h-[22px] flex justify-center items-center"
          v-html="!openBoxUserFilter ? ArrowBlackDown : ArrowBlackUp"
        ></span>
      </button>
      <div
        v-if="openBoxUserFilter"
        class="panel-list-user-status absolute z-[1000] min-w-[223px] left-[50%] translate-x-[-50%] min-h-[164px] rounded-[8px] p-[8px] flex flex-col justify-start items-center gap-[8px] bg-white border-[1px] border-solid border-[#F2F4F7] top-[48px]"
        style="box-shadow: 0px 6px 10px 0px #00000024"
      >
        <button
          v-for="status in listStatusUserFilter()"
          :class="{
            'min-w-[207px] h-[44px] rounded-[8px] px-[20px] py-[12px] flex justify-start items-center gap-[12px] bg-transparent hover:bg-[#FEF6EE]': true,
            '!bg-[#F38744]': status.id === selectedStatusUserFilter.id
          }"
          :key="status.id"
          @click="onSelectStatusUserFilter(status)"
        >
          <span
            v-html="
              status.id === selectedStatusUserFilter.id
                ? UserWhiteIcon
                : UserGrayIcon
            "
          ></span>
          <span
            :class="{
              'text-[#1D2939] noto500 text-[14px] leading-[18px]': true,
              '!text-white': status.id === selectedStatusUserFilter.id
            }"
            >{{ status.name }}</span
          >
        </button>
      </div>
    </div>
  </div>
  <div
    ref="zoneSelectUser"
    v-else
    class="box-select-status-user w-full md:min-w-[200px]"
  >
    <div class="btn-select relative w-full min-h-[44px]">
      <button
        v-on:click="handleOpenOrCloseSelectedStatusUserFilter"
        class="min-w-[237px] w-full h-[44px] rounded-[8px] border-solid border-[1px] border-[#D0D5DD] px-[20px] py-[12px] flex justify-center items-center gap-[12px] bg-white"
      >
        <span v-html="UserBlackIcon"></span>
        <span
          class="text-black noto500 text-[14px] leading-[18px] text-center"
          >{{ selectedStatusUserFilter?.name }}</span
        >
        <span
          class="max-w-[22px] min-w-[22px] max-h-[22px] flex justify-center items-center"
          v-html="!openBoxUserFilter ? ArrowBlackDown : ArrowBlackUp"
        ></span>
      </button>
      <div
        v-if="openBoxUserFilter"
        class="panel-list-user-status absolute z-[1000] min-w-[223px] w-full left-[50%] translate-x-[-50%] min-h-[164px] rounded-[8px] p-[8px] flex flex-col justify-start items-center gap-[8px] bg-white border-[1px] border-solid border-[#F2F4F7] top-[48px]"
        style="box-shadow: 0px 6px 10px 0px #00000024"
      >
        <button
          v-for="status in listStatusUserFilter()"
          :class="{
            'min-w-[207px] w-full h-[44px] rounded-[8px] px-[20px] py-[12px] flex justify-start items-center gap-[12px] bg-transparent hover:bg-[#FEF6EE]': true,
            '!bg-[#F38744]': status.id === selectedStatusUserFilter.id
          }"
          :key="status.id"
          @click="onSelectStatusUserFilter(status)"
        >
          <span
            v-html="
              status.id === selectedStatusUserFilter.id
                ? UserWhiteIcon
                : UserGrayIcon
            "
          ></span>
          <span
            :class="{
              'text-[#1D2939] noto500 text-[14px] leading-[18px]': true,
              '!text-white': status.id === selectedStatusUserFilter.id
            }"
            >{{ status.name }}</span
          >
        </button>
      </div>
    </div>
  </div>
</template>
<script setup>
import { computed, onBeforeUnmount, onMounted, ref } from 'vue';
import { listStatusUserFilter } from '../../../../assets/constant/variable';
import { useI18n } from 'vue-i18n';
import {
  ArrowBlackDown,
  ArrowBlackUp,
  UserBlackIcon,
  UserGrayIcon,
  UserWhiteIcon
} from '../../../../assets/constant/svg';
import { useRoute } from 'vue-router';
import { useStore } from 'vuex';

const store = useStore();
const route = useRoute();
const layoutMobile = computed(() => store.state.layoutMobile);
const props = defineProps({
  handleSelectStatusUserFilter: {
    type: Function,
    required: true
  }
});
const { t } = useI18n();
const zoneSelectUser = ref(null);
const openBoxUserFilter = ref(false);
const selectedStatusUserFilter = ref({
  id: 0,
  status: 'all',
  name: t('common.all_accounts')
});
const handleOpenOrCloseSelectedStatusUserFilter = (event) => {
  event.stopPropagation();
  openBoxUserFilter.value = !openBoxUserFilter.value;
};
const onSelectStatusUserFilter = (status) => {
  selectedStatusUserFilter.value = status;
  props.handleSelectStatusUserFilter(status);
  openBoxUserFilter.value = false;
};
const handleGetQueryFromPath = () => {
  const status = route.query.status;
  if (status) {
    const statusMatch = listStatusUserFilter()?.find((item) => {
      return item.status === status;
    });
    if (statusMatch) {
      onSelectStatusUserFilter(statusMatch);
    }
  }
};
const handleClickOutside = (event) => {
  if (zoneSelectUser.value && !zoneSelectUser.value.contains(event.target)) {
    openBoxUserFilter.value = false;
  }
};

onBeforeUnmount(() => {
  document.removeEventListener('click', handleClickOutside);
});
onMounted(() => {
  handleGetQueryFromPath();
  document.addEventListener('click', handleClickOutside);
});
</script>
