<template>
  <div
    class="flex justify-start items-center gap-[12px] mt-[12px] pb-[12px] border-solid border-b-[1px] border-[#D0D5DD]"
  >
    <div
      class="relative w-[50px] h-[50px] flex justify-center items-center rounded-lg"
    >
      <img
        :src="getAvatarUserByName(data?.avatar ?? '')"
        class="rounded-lg w-[48px] h-[48px] !max-w-[48px] !max-h-[48px] object-cover !border-solid !border-[2px] !border-[#F04438]"
        @error="(e) => handleImageError(e, 'user')"
      />
      <span
        class="absolute top-[42px] left-[50%] translate-x-[-50%]"
        v-html="LiveIcon"
      ></span>
    </div>
    <div class="flex-1 flex flex-col justify-center items-start">
      <h3 class="text-[#1D2939] noto700 text-[16px] leading-[20px]">
        {{ data?.nickName ?? '-' }}
      </h3>
      <h5 class="text-[#667085] noto400 text-[14px] leading-[18px]">
        {{ data?.userName ?? '-' }}
      </h5>
    </div>
    <button
      @click="updateEnableLive"
      class="w-[60px] h-[38px] flex justify-center items-center rounded-[8px] bg-[#F04438] text-white noto500 text-[14px] leading-[18px]"
    >
      STOP
    </button>
  </div>
</template>
<script setup>
import { LiveIcon } from '../../../../assets/constant/svg';
import {
  addModal,
  getAvatarUserByName,
  handleImageError
} from '../../../../helpers';
import ConfirmEnableLive from './ConfirmEnableLive.vue';
import { useStore } from 'vuex';
import { getToken } from '../../../../helpers/token';
const props = defineProps({
  data: {
    type: Object,
    required: true
  }
});
const store = useStore();

const updateEnableLive = () => {
  addModal(ConfirmEnableLive, {
    onConfirm: () => {
      store.dispatch('enableLive', {
        users: [
          {
            user_id: props.data?._id,
            enableLive: false
          }
        ],
        onUpdateSuccess: () => {},
        token: getToken()
      });
    }
  });
};
</script>