<template>
  <div class="w-[800px] h-[340px] my-[42px] rounded-[12px] bg-white">
    <div class="title w-full h-[54px] py-[12px] px-[20px] flex justify-between">
      <h2 class="form-name text-[20px] notosans700 text-[#101828] font-bold">
        {{ $t('common.change_pwd') }}
      </h2>
      <button
        class="btn-close-form notosans400 text-[13px] text-[#667085]"
        v-on:click="onClose"
      >
        {{ $t('common.close') }}
      </button>
    </div>
    <div
      class="w-full h-[220px] p-[20px] pt-[16px] border-y-[1px] border-solid border-[#D0D5DD]"
    >
      <div
        class="w-full h-[84px] border-b-[1px] border-solid border-[#D0D5DD] mt-[12px]"
      >
        <label for="new_password" class="relative">
          <span
            class="w-full h-[22px] text-left notosans500 text-[14px] leading-[22px] text-[#344054]"
            >{{ $t('common.new_password') }}</span
          >
          <input
            v-model="newPassword"
            id="new_password"
            :type="showNewPassword ? 'text' : 'password'"
            class="px-[14px] pr-[100px] py-[10px] rounded-[8px] w-full h-[44px] border-[1px] border-solid border-[#D0D5DD] mt-[6px] outline-none"
          />
          <span
            :class="{
              'absolute right-[10px] bottom-0 cursor-pointer': true,
              eye: showNewPassword
            }"
            v-html="PasswordShowIcon"
            v-on:click="showOrHidePassword('new')"
          ></span>
        </label>
      </div>
      <div class="w-full h-[84px] mt-[12px]">
        <label for="new_password_confirm_" class="relative">
          <span
            class="w-full h-[22px] text-left notosans500 text-[14px] leading-[22px] text-[#344054]"
            >新パスワード確認</span
          >
          <input
            v-model="newPasswordConfirm"
            id="new_password_confirm_"
            :type="showOldPasswordConfirm ? 'text' : 'password'"
            class="px-[14px] pr-[100px] py-[10px] rounded-[8px] w-full h-[44px] border-[1px] border-solid border-[#D0D5DD] mt-[6px] outline-none"
          />
          <span
            :class="{
              'absolute right-[10px] bottom-0 cursor-pointer': true,
              eye: showOldPasswordConfirm
            }"
            v-html="PasswordShowIcon"
            v-on:click="showOrHidePassword('confirm')"
          ></span>
        </label>
      </div>
    </div>
    <div class="button w-full h-[66px] py-[12px] px-[20px] flex justify-center">
      <button
        :class="`fill-primary-text-white flex-1 flex gap-[5px] ${
          processSaveUser && `disabled`
        } `"
        v-on:click="handleKeep"
      >
        <span v-html="SaveIcon"></span>
        <span>{{ $t('common.keep') }}</span>
        <span class="flex justify-center items-center" v-if="processSaveUser">
          <a-spin :indicator="indicator" />
        </span>
      </button>
    </div>
  </div>
</template>
  <script setup>
import { computed, ref, watch } from 'vue';
// import {
//   PasswordShowIcon,
//   SaveIcon
// } from '../../../../assets/constant/svg_exports';
import {
  clearCharactersNotSupported,
  containsNonWhitespaceCharacters,
  showAlert
} from '../../../../helpers';
import { useI18n } from 'vue-i18n';
import { getToken } from '../../../../helpers/token';
import { useStore } from 'vuex';
import { SaveIcon } from '../../../../assets/constant/svg';
import { PasswordShowIcon } from '../../../../assets/constant/constant';

const props = defineProps({
  onClose: {
    type: Function,
    required: true
  },
  userId: {
    type: String,
    required: true
  }
});

const { t } = useI18n();
const store = useStore();

const newPassword = ref('');
const newPasswordConfirm = ref('');
const processSaveUser = ref(false);

const showNewPassword = ref(false);
const showOldPasswordConfirm = ref(false);

const showOrHidePassword = (key) => {
  if (key === 'new') {
    showNewPassword.value = !showNewPassword.value;
  } else if (key === 'confirm') {
    showOldPasswordConfirm.value = !showOldPasswordConfirm.value;
  }
};

watch(newPassword, () => {
  newPassword.value = clearCharactersNotSupported(newPassword.value);
});
watch(newPasswordConfirm, () => {
  newPasswordConfirm.value = clearCharactersNotSupported(
    newPasswordConfirm.value
  );
});
const handleKeep = () => {
  if (
    newPassword.value === newPasswordConfirm.value &&
    containsNonWhitespaceCharacters(newPassword.value)
  ) {
    store.dispatch('updatePassword', {
      token: getToken(),
      data: {
        users: [
          {
            password: newPassword.value,
            user_id: props?.userId
          }
        ]
      },
      onSuccess: () => {
        setTimeout(() => {
          window.location.reload();
        }, 1000);
        props.onClose();
      }
    });
  } else {
    showAlert('warning', '確認パスワードが正しくありません', 'top-center');
  }
};
</script>
  <style lang="scss">
.eye::after {
  position: absolute;
  content: '';
  top: 50%;
  right: 50%;
  width: 25px;
  height: 2px;
  transform: translateX(50%) translateY(-50%) rotate(45deg);
  background-color: var(--orange-color);
}
</style>
  